import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { addMessage } from "../reducers/MessageReducer";
import { useDispatch } from "react-redux";
import parse from "html-react-parser";

export default function SnackBar({ message, open }) {
    const dispatch = useDispatch();
    const handleClose = (e) => {
        dispatch(addMessage("", false));
    };

    const [snackBarTop, setSnackbarTop] = useState(786);

    const handleMessage = (event) => {
        const payload = event.data;

        if (payload.action) {
            switch (payload.action) {
                //if host, once a roomId is created from reactApp update watchmojo url
                case "gameHeight":
                    setSnackbarTop(payload.height - 50);
                    break;
            }
        }
    };

    const handleWindowResize = () => {
        let message = { action: "gameHeight" };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);
        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={(e) => handleClose(e)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                open={open}
                onClose={(e) => handleClose(e)}
                message={parse(message)}
                action={action}
                autoHideDuration={5000}
                sx={{
                    pointerEvents: "none",
                    "& .MuiSnackbarContent-root": {
                        backgroundColor: "black",
                    },
                    "&.MuiSnackbar-root": {
                        // top: { xs: snackBarTop, md: snackBarTop },
                        bottom: { xs: "0", md: "40px" },
                        left: { xs: "unset", md: "unset" },
                        right: { xs: ".5rem", md: "24px" },
                    },
                }}
            />
        </div>
    );
}
