import { Box, Typography } from "@mui/material";
import React from "react";
import ActiveGames from "../ActiveGames/ActiveGames";
import CashBattleInfo from "../CashBattleInfo/CashBattleInfo";
import { getLCP } from "web-vitals";
import { getLabel } from "../../helpers/Global";
import { coin } from "../../helpers/constant";

export default function KeepPlaying({
    theme,
    uiLabels,
    multiplayerCategory,
    sx = { px: { xs: 0, md: "10%", lg: "17%", xl: "25%" } },
}) {
    return (
        <>
            <Typography
                variant="body1"
                sx={{
                    fontSize: { xs: "16px", md: "22px" },
                    fontWeight: 700,
                    width: "100%",
                    textAlign: "center",
                    textTransform: "uppercase",
                }}
                dangerouslySetInnerHTML={{
                    __html: getLabel(
                        uiLabels,
                        "labelKeepPlaying",
                        `Keep playing to earn MojoPoints and join our next CASH BATTLE!`
                    ).replace(/#0/g, `${coin}&nbsp;`),
                }}
            ></Typography>

            <Box sx={{ my: 5 }}>
                <CashBattleInfo uiLabels={uiLabels} />
            </Box>
            <Box sx={{ px: sx && sx.px ? sx.px : 0 }}>
                <ActiveGames
                    uiLabels={uiLabels}
                    header=""
                    games={multiplayerCategory}
                    theme={theme}
                />
            </Box>
        </>
    );
}
