const initialState = {
    playerScores: {},
};

const PlayersReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_PLAYER_SCORE":
            const copy = { ...state.playerScores };
            copy[action.playerId] = action.score;

            return Object.assign({}, state, {
                playerScores: copy,
            });

        default:
            return state;
    }
};

//player score
export const updatePlayerScore = (playerId, score) => {
    return {
        type: "UPDATE_PLAYER_SCORE",
        playerId: playerId,
        score: score,
    };
};

export default PlayersReducer;
