import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
    getLabel,
    getTotalPoints,
    isCashBattle,
    isChampion,
    isPrizeWinner,
    playerRedeemed,
} from "../../helpers/Global";
import GeneralButton from "../Button/Button";
import Player from "../Player/Player";
import { AVATAR_SIZE, coin } from "../../helpers/constant";
import CashBattleInfo from "../CashBattleInfo/CashBattleInfo";

export default function Prize({
    requiredPoints,
    me,
    winner,
    theme,
    uiLabels,
    handleRedeemPrize,
}) {
    const getWinningMessage = useCallback(() => {
        const sx = {
            fontSize: { xs: "18px", md: "20px" },
            fontWeight: 700,
            width: "100%",
            textAlign: "center",
            px: { xs: 1, md: 0 },
        };

        if (isCashBattle(requiredPoints, winner)) {
            if (isChampion(me)) {
                const prizeAmount = isPrizeWinner(me) ? me.prizeAmount : 0;
                return (
                    <Typography
                        variant="body1"
                        sx={sx}
                        dangerouslySetInnerHTML={{
                            __html: `${getLabel(
                                uiLabels,
                                "messageEndScreenCashChampion",
                                `You have won USD$
                            ${prizeAmount} prepaid credit
                            card and infinite bragging rights! Please click the
                            button below to redeem your prize.`
                            ).replace(/#0/g, prizeAmount)}`,
                        }}
                    ></Typography>
                );
            }
            const prizeAmount = isPrizeWinner(winner) ? winner.prizeAmount : 0;

            return (
                <Typography
                    variant="body1"
                    sx={sx}
                    dangerouslySetInnerHTML={{
                        __html: `${getLabel(
                            uiLabels,
                            "messageEndScreenNotCashChampion",
                            `Today's Champion won USD$${prizeAmount} prepaid credit card`
                        ).replace(/#0/g, prizeAmount)}`,
                    }}
                ></Typography>
            );
        }

        if (isChampion(me)) {
            const totalPoints = getTotalPoints(me);
            return (
                <Typography
                    variant="body1"
                    sx={sx}
                    dangerouslySetInnerHTML={{
                        __html: getLabel(
                            uiLabels,
                            "messageEndScreenChampion",
                            `You've earned ${totalPoints} MojoPoints and automatic entry
                                into our next CASH BATTLE`
                        )
                            .replace(/#0/g, totalPoints)
                            .replace(/#0/g, `${coin}&nbsp;`),
                    }}
                ></Typography>
            );
        }

        const totalPoints = getTotalPoints(winner);

        if (winner && winner.isBot) {
            return <></>;
        }

        return (
            <Typography
                variant="body1"
                sx={sx}
                dangerouslySetInnerHTML={{
                    __html: getLabel(
                        uiLabels,
                        "messageEndScreenNotChampion",
                        `Today's Champion has earned ${totalPoints} MojoPoints and automatic entry into our next CASH BATTLE`
                    )
                        .replace(/#0/g, totalPoints)
                        .replace(/#0/g, `${coin}&nbsp;`),
                }}
            ></Typography>
        );
    }, [winner, me, requiredPoints]);

    return (
        <>
            <Box display="block" sx={{ pl: { xs: 0, lg: 0 }, mt: 1, pt: 1 }}>
                <Typography
                    color={theme.palette.wmColor.main}
                    component="h5"
                    variant="h5"
                    align="center"
                    sx={{
                        display: { xs: "block", md: "block" },
                        pb: "6px",
                        textTransform: "uppercase",
                        letterSpacing: 2,
                        fontFamily: theme.font.family,
                        fontSize: "3rem",
                        px: { xs: 2, md: 0 },
                        lineHeight: 1,
                    }}
                >
                    {isCashBattle(requiredPoints, winner)
                        ? getLabel(
                              uiLabels,
                              "labelCashBattleChampion",
                              "Cash Battle Champion"
                          )
                        : getLabel(
                              uiLabels,
                              "labelPointsBattleChampion",
                              "Points Battle Champion"
                          )}
                </Typography>
                <Box display={"flex"} justifyContent={"center"} sx={{ p: 5 }}>
                    {winner && (
                        <Player
                            player={winner}
                            forceActive={true}
                            questionStart={false}
                            sx={{
                                avatar: {
                                    maxWidth: "100% !important",
                                    size: AVATAR_SIZE.endScreen,
                                    fontSize: { xs: "1rem", md: "1.5rem" },
                                },
                            }}
                        />
                    )}
                </Box>

                {getWinningMessage()}

                {isPrizeWinner(me) && !playerRedeemed(me) && (
                    <Box display={"flex"} justifyContent={"center"}>
                        <GeneralButton
                            bgcolor={theme.palette.secondaryButton.main}
                            color={theme.palette.secondaryButton.textColor}
                            hoverColor={theme.palette.hoverColor.main}
                            handleButtonClick={handleRedeemPrize}
                            minHeight={"48px"}
                            maxHeight={"75px"}
                            width={"300px"}
                            mt={5}
                        >
                            {getLabel(uiLabels, "btnRedeem", "Redeem")}
                        </GeneralButton>
                    </Box>
                )}
            </Box>
        </>
    );
}
