import { Box } from "@mui/material";
import React from "react";
import PowerUp from "./PowerUp";

export default function PowerUps({
    hintInactive,
    powerUps = [],
    questionStart,
    clicked,
    spectator,
    points,
    usedPowerUps = [],
    swapInactive,
    theme,
    uiLabels,
}) {
    return (
        <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ gap: "12px" }}
        >
            {powerUps.map((powerUp) => {
                return (
                    <PowerUp
                        hintInactive={hintInactive}
                        key={powerUp.id}
                        powerUp={powerUp}
                        questionStart={questionStart}
                        clicked={clicked}
                        spectator={spectator}
                        points={points}
                        usedPowerUps={usedPowerUps}
                        swapInactive={swapInactive}
                        theme={theme}
                        uiLabels={uiLabels}
                    />
                );
            })}
        </Box>
    );
}
