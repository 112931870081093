const initialState = {
    token: null,
};

const TokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case "NEW_TOKEN":
            return Object.assign({}, state, {
                token: action.data.token,
            });

        default:
            return state;
    }
};

//adding the token
export const addToken = (token) => {
    return {
        type: "NEW_TOKEN",
        data: { token },
    };
};

export default TokenReducer;
