import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import GameReducer from "./GameReducer";
import PlayerReducer from "./PlayerReducer";
import SocketReducer from "./SocketReducer";
import TokenReducer from "./TokenReducer";
import SettingsReducer from "./SettingsReducer";
import VideoReducer from "./VideoReducer";
import MessageReducer from "./MessageReducer";
import TimerReducer from "./TimerReducer";
import QuestionsReducer from "./QuestionsReducer";
import AnswersReducer from "./AnswersReducer";
import AlertReducer from "./AlertReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import PlayersReducer from "./PlayersReducers";
import OnlinePlayersReducer from "./OnlinePlayersReducer";

const reducer = combineReducers({
    game: GameReducer,
    player: PlayerReducer,
    socket: SocketReducer,
    token: TokenReducer,
    settings: SettingsReducer,
    video: VideoReducer,
    message: MessageReducer,
    timer: TimerReducer,
    question: QuestionsReducer,
    answers: AnswersReducer,
    alert: AlertReducer,
    players: PlayersReducer,
    onlinePlayers: OnlinePlayersReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
