import React from "react";
import { Box, Typography } from "@mui/material";
import cssStyle from "./HowToSection.module.css";
import { getLabel } from "../../helpers/Global";
import { coin } from "../../helpers/constant";

export default function HowToSection({ uiLabels }) {
    return (
        <>
            <Box>
                <Typography paragraph className={`${cssStyle.textWhite}`}>
                    {getLabel(
                        uiLabels,
                        "howToPlayInfo1",
                        `There are lots of awesome ways to play WatchMojo Trivia!
                        Check out our Cash Battles, Daily Points Battles, and
                        multiplayer games! With new topics and questions every
                        day, there’s something for everyone.`
                    )}
                </Typography>

                <Typography
                    className={`${cssStyle.textWhite} `}
                    dangerouslySetInnerHTML={{
                        __html: `<u>${getLabel(
                            uiLabels,
                            "labelCashBattles",
                            "Cash Battles"
                        )}</u><br>${getLabel(
                            uiLabels,
                            "howToPlayInfoCashBattles",
                            "Cash Battles feature three thrilling rounds of trivia where players compete for REAL CASH PRIZES! To join a Cash Battle, redeem MojoPoints earned by competing in Daily Points Battles and multiplayer games. Check WatchMojo.com/play for upcoming Cash Battle announcements!"
                        ).replace(/#0/g, `${coin}&nbsp;`)}`,
                    }}
                ></Typography>

                <Typography
                    sx={{ mt: 3 }}
                    className={`${cssStyle.textWhite} `}
                    dangerouslySetInnerHTML={{
                        __html: `<u>${getLabel(
                            uiLabels,
                            "labelDailyPointsBattles",
                            "Daily Points Battles"
                        )}</u><br>${getLabel(
                            uiLabels,
                            "howToPlayInfoPointsBattles1",
                            "Boost your MojoPoints daily and qualify for upcoming Cash Battles! Gameplay is the same, with different exciting trivia categories every day. Daily winners automatically qualify for the next Cash Battle, but everyone earns more points! Points Battles restart every hour, but you can only play once a day."
                        ).replace(/#0/g, `${coin}&nbsp;`)} ${getLabel(
                            uiLabels,
                            "howToPlayInfoPointsBattles2",
                            `Sign in at <a href="javascript: window.parent.location = '${process.env.REACT_APP_WM_URL}/play';">WatchMojo.com/play</a>!`
                        ).replace(
                            /#0/g,
                            `<a href="javascript: window.parent.location = '${process.env.REACT_APP_WM_URL}/play';">WatchMojo.com/play</a>`
                        )}`,
                    }}
                ></Typography>

                <Typography
                    sx={{ mt: 3 }}
                    className={`${cssStyle.textWhite} `}
                    dangerouslySetInnerHTML={{
                        __html: `<u>${getLabel(
                            uiLabels,
                            "howToPlayMultiplayerGames",
                            "Multiplayer Games"
                        )}</u><br>${getLabel(
                            uiLabels,
                            "howToPlayMultiplayerGamesInfo",
                            "Play at your own pace! Find Multiplayer Games under the “Play Now!” tab at WatchMojo.com/multiplayer-trivia. We offer Film, TV, Comics, Music, Video Game trivia, and more. Plus, you earn MojoPoints while you play! Check it out!"
                        ).replace(/#0/g, `${coin}&nbsp;`)}`,
                    }}
                ></Typography>
            </Box>
        </>
    );
}
