const initialState = {
    id: null,
    question: "",
    videoTitle: "",
    type: null,
    answers: null,
    thumbnail: null,
    maxSeconds: null,
};

const QuestionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_QUESTION":
            return Object.assign({}, state, {
                id: action.id,
                question: action.question,
                videoTitle: action.videoTitle,
                type: action.questionType,
                answers: action.answers,
                thumbnail: action.thumbnail,
            });

        case "UPDATE_MAX_SECONDS":
            return Object.assign({}, state, {
                maxSeconds: action.data,
            });

        default:
            return state;
    }
};

//update question
export const updateQuestion = (
    id,
    question,
    videoTitle,
    questionType,
    answers,
    thumbnail
) => {
    return {
        type: "UPDATE_QUESTION",
        id: id,
        question: question,
        videoTitle: videoTitle,
        questionType: questionType,
        answers: answers,
        thumbnail: thumbnail,
    };
};

//update maxSeconds
export const updateMaxSeconds = (seconds) => {
    return {
        type: "UPDATE_MAX_SECONDS",
        data: seconds,
    };
};

export default QuestionsReducer;
