import React, { useRef } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useSelector } from "react-redux";

import PlayerLobbyView from "../PlayerLobbyView/PlayerLobbyView";
import { getLabel } from "../../helpers/Global";

const Settings = ({ contentContainerHeight, uiLabels }) => {
    const actionContainer = useRef();

    const theme = useTheme();

    let currentTitle = useSelector(({ settings }) => settings.currentTitle);

    return (
        <Box
            sx={{
                height: { xs: "auto", md: `100%` },
            }}
        >
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        pl: { xs: theme.pLeft.main, md: theme.px16.main },
                        pr: { xs: theme.pRight.main, md: theme.px16.main },
                    }}
                >
                    <Box
                        ref={actionContainer}
                        sx={{
                            width: "100%",
                            pl: { xs: theme.pLeft.main, md: theme.px16.main },
                            pr: { xs: theme.pRight.main, md: theme.px16.main },
                            pt: { xs: theme.px10.main, md: theme.px10.main },
                            pb: theme.px8.main,
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                color: `${theme.palette.wmColor.main}`,
                                textAlign: "center",
                                textTransform: "uppercase",
                                fontWeight: "medium",
                                fontSize: { xs: 14, md: 20 },
                                lineHeight: "1",
                            }}
                        >
                            {getLabel(uiLabels, "labelCategory", "Category")}:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: { xs: 20, md: 30 },
                                textTransform: "capitalize",
                                lineHeight: "1.1",
                            }}
                        >
                            {currentTitle}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <PlayerLobbyView />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Settings;
