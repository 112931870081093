import React, { useEffect, useState } from "react";
import { GAME_STATUS, SOCKET_EVENTS } from "../../helpers/constant";
import HomeCountdown from "../../components/HomeCountdown/HomeCountdown";
import { getLabel, isCashBattle } from "../../helpers/Global";
import { useSelector } from "react-redux";
import OnlinePlayers from "../../components/OnlinePlayers/OnlinePlayers";
import { Box, Button, Snackbar, Typography, useTheme } from "@mui/material";
import ButtonJoin from "../../components/Button/ButtonJoin";
import GameProgress from "../../components/GameProgress/GameProgress";

export default function StatusWidget({
    uiLabels,
    gameStatus,
    player,
    socketFromStore,
}) {
    const theme = useTheme();
    const requiredPoints = useSelector(({ game }) => game.requiredPoints);

    const availableOL = useSelector(
        ({ onlinePlayers }) => onlinePlayers.availableOL
    );

    useEffect(() => {
        if (!socketFromStore) {
            return;
        }

        //get room details
        socketFromStore.emit(SOCKET_EVENTS.ROOM_DETAILS);
    }, [socketFromStore]);

    //setup button sx
    const buttonSX = theme.home.login.joinCatchUpButton;
    buttonSX.minWidth = "100px";
    buttonSX.backgroundColor = theme.palette.wmColor.main;
    buttonSX.ml = 1;
    buttonSX.mt = 3;
    delete buttonSX.mb;

    return (
        <>
            <Box
                display={"flex"}
                alignItems={"center"}
                sx={{
                    height: `${window.innerHeight}px`,
                }}
            >
                {[GAME_STATUS.WAITING, GAME_STATUS.COUNTDOWN].includes(
                    gameStatus
                ) && (
                    <Box
                        sx={{ px: 1, width: "100%" }}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "16px", md: "20px" },
                                    fontWeight: 500,
                                    width: "100%",
                                }}
                            >
                                {`${getLabel(
                                    uiLabels,
                                    "labelGameStartsIn",
                                    "Game Starts In"
                                )}:`}
                            </Typography>
                            <HomeCountdown
                                gameStatus={gameStatus}
                                uiLabels={uiLabels}
                                listenerEvent={
                                    gameStatus === GAME_STATUS.COUNTDOWN
                                        ? SOCKET_EVENTS.START_COUNTDOWN
                                        : SOCKET_EVENTS.WAITING_COUNTDOWN
                                }
                                hourLabel={""}
                                minuteLabel={""}
                                secondLabel={""}
                                sx={{
                                    clockHeight: "36px",
                                    clockWidth: "46px",
                                    clock: {
                                        fontSize: {
                                            xs: "24px",
                                            md: "24px",
                                        },
                                    },
                                }}
                            />
                        </Box>
                        <Button
                            variant="contained"
                            sx={buttonSX}
                            onClick={() => {
                                window.top.location = `${process.env.REACT_APP_WM_URL}/play`;
                            }}
                        >
                            {getLabel(uiLabels, "btnJoinGame", "Join Game")}
                        </Button>
                    </Box>
                )}

                {(!gameStatus ||
                    ![GAME_STATUS.COUNTDOWN].includes(gameStatus)) &&
                    !isCashBattle(requiredPoints) &&
                    availableOL > 0 && (
                        <Box
                            sx={{
                                width: "100%",
                                pt: "1px",
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "14px", md: "16px" },
                                    fontWeight: 500,
                                    width: "100%",
                                    px: 1,
                                    textAlign: "center",
                                }}
                            >
                                {`${getLabel(
                                    uiLabels,
                                    "labelOnlinePlayers",
                                    "Challenge online players to start a game!"
                                )}`}
                            </Typography>
                            <OnlinePlayers
                                me={player}
                                uiLabels={uiLabels}
                                gameStatus={gameStatus}
                                socketFromStore={socketFromStore}
                            />
                        </Box>
                    )}
            </Box>
        </>
    );
}
