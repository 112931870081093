import React, { useEffect, useState, useCallback } from "react";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Grid,
    IconButton,
    Link,
    Typography,
} from "@mui/material";

import cssStyle from "./LoggedinPlayer.module.css";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import LinkIcon from "@mui/icons-material/Link";
import {
    formatNumber,
    getLabel,
    handleInviteClick,
    titleCase,
} from "../../helpers/Global";
import {
    SOCKET_ACTIONS,
    SOCKET_EVENTS,
    SUPER_GAME_TEXT,
    coin,
    coinTitle,
} from "../../helpers/constant";
import EditIcon from "@mui/icons-material/Edit";
import DialogGuest from "../DialogGuest/DialogGuest";
import useSocketEvents from "../../hooks/useSocketEvents";
import { updatePlayerStats, updatePoints } from "../../reducers/PlayerReducer";

export default function LoggedinPlayer({
    player,
    gameStatus = null,
    declinedPoints,
    handleJoinQueue,
    handleHowToPlay,
    handleMojoPoints,
    uiLabels,
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [guestDialog, setGuestDialog] = useState(false);
    const socketFromStore = useSelector(({ socket }) => socket.socket);

    function handleGuestLogin() {
        setGuestDialog(true);
    }

    useEffect(() => {
        socketFromStore.emit(
            SOCKET_EVENTS.PLAYER_STATS,
            player ? player.playerId : null
        );
    }, [socketFromStore]);

    const handleTermsCondition = () => {
        window.open(`${process.env.REACT_APP_WM_URL}/privacy-policy`);
    };

    const handlePlayerStats = useCallback(
        (playerStats) => {
            dispatch(updatePlayerStats(playerStats));
            dispatch(updatePoints(playerStats.loyaltyPoints ?? 0));
        },
        [dispatch]
    );

    //add PLAYER_STATS event,
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_EVENTS.PLAYER_STATS,
        null,
        handlePlayerStats
    );

    function showQueue() {
        return (
            <>
                <Grid item xs={4} md={5}>
                    <Typography
                        variant="body1"
                        sx={{
                            color: "#16F5B4",
                            fontSize: theme.font.size.playerStats,
                            fontWeight: 900,
                            textAlign: "center",
                        }}
                    >
                        {player.playerQueue && !isNaN(player.playerQueue)
                            ? formatNumber(player.playerQueue)
                            : "0"}
                    </Typography>
                </Grid>
                <Grid item xs={8} md={7} sx={{ p: 0 }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{ height: theme.height.h100 }}
                    >
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "12px", sm: "14px" },
                                    fontWeight: 500,
                                }}
                            >
                                {getLabel(
                                    uiLabels,
                                    "labelPosition",
                                    "Your position in the queue"
                                )}
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    color: "#99A8A4",
                                }}
                            >
                                {getLabel(
                                    uiLabels,
                                    "labelFirst1k",
                                    "First 1,000 players play."
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </>
        );
    }

    function showScore() {
        return (
            <>
                <Box display={"flex"} alignItems={"center"}>
                    <Box className={cssStyle.statsNumber}>
                        <Typography
                            variant="body1"
                            sx={{
                                color: theme.palette.wmColor.main,
                                fontSize: theme.font.size.playerStats,
                                fontWeight: 900,
                                textAlign: "right",
                            }}
                        >
                            {formatNumber(
                                player.playerStats
                                    ? player.playerStats.correctAnswer
                                    : 0
                            )}
                        </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: theme.font.size.playerStatsLabel,
                                fontWeight: 500,
                            }}
                        >
                            {getLabel(
                                uiLabels,
                                "labelCorrectAnswersThisWeek",
                                "Correct Answers This Week"
                            )}
                        </Typography>
                    </Box>
                </Box>
            </>
        );
    }

    function showAllTimeCorrectAnswer() {
        return (
            <>
                <Box display={"flex"} alignItems={"center"}>
                    <Box className={cssStyle.statsNumber}>
                        <Typography
                            variant="body1"
                            sx={{
                                color: theme.palette.wmColor.main,
                                fontSize: theme.font.size.playerStats,
                                fontWeight: 900,
                                textAlign: "right",
                            }}
                        >
                            {formatNumber(
                                player.playerStats
                                    ? player.playerStats.allTimeCorrectAnswer
                                    : 0
                            )}
                        </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: theme.font.size.playerStatsLabel,
                                fontWeight: 500,
                            }}
                        >
                            {getLabel(
                                uiLabels,
                                "labelAllTimeCorrectAnswers",
                                "Correct Answers All Time"
                            )}
                        </Typography>
                    </Box>
                </Box>
            </>
        );
    }

    function showLoyaltyPoints() {
        return (
            <>
                <Box display={"flex"} alignItems={"center"}>
                    <Box className={cssStyle.statsNumber}>
                        <Typography
                            variant="body1"
                            sx={{
                                color: theme.palette.wmColor.main,
                                fontSize: theme.font.size.playerStats,
                                fontWeight: 900,
                                textAlign: "right",
                            }}
                        >
                            {formatNumber(player.points ?? 0)}
                        </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: theme.font.size.playerStatsLabel,
                                fontWeight: 500,
                                display: "flex",
                                alignItems: "center",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: `${coinTitle}&nbsp;&nbsp;${"MojoPoints"}`,
                            }}
                        ></Typography>
                    </Box>
                </Box>
            </>
        );
    }

    function showAvatar() {
        return (
            <>
                <Box display={"flex"} justifyContent={"right"}>
                    <Box>
                        <Box
                            display={"flex"}
                            justifyContent={"center"}
                            sx={{
                                position: "relative",
                            }}
                        >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                badgeContent={
                                    <IconButton
                                        className={cssStyle.playerEdit}
                                        onClick={handleGuestLogin}
                                    >
                                        <EditIcon
                                            className={cssStyle.editIcon}
                                        ></EditIcon>
                                    </IconButton>
                                }
                            >
                                <Avatar
                                    src={player.playerAvatar}
                                    sx={theme.home.login.playerAvatar}
                                ></Avatar>
                            </Badge>
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: {
                                    xs: "0.7rem",
                                    sm: "0.9rem",
                                    md: "1.2rem",
                                },
                                fontWeight: 500,
                                textAlign: "center",
                                overflowWrap: "anywhere",
                            }}
                        >
                            {player.playerName}
                        </Typography>
                    </Box>
                </Box>
            </>
        );
    }

    function showButtons() {
        let buttons = [
            <Box key={1} display={"flex"} justifyContent={"center"}>
                <Button
                    variant="contained"
                    sx={theme.home.login.inviteButton}
                    onClick={() => {
                        handleInviteClick(player);
                    }}
                    startIcon={
                        <LinkIcon
                            sx={{ mr: "5px", transform: "rotate(130deg)" }}
                        />
                    }
                >
                    <Typography
                        sx={{ display: "flex", alignItems: "center" }}
                        dangerouslySetInnerHTML={{
                            __html: getLabel(
                                uiLabels,
                                "btnInviteFriends500",
                                "Invite friends and get 500 MojoPoints"
                            ).replace(/#0/g, `&nbsp;${coin}&nbsp;`),
                        }}
                    ></Typography>
                </Button>
            </Box>,
            <Box key={2} display={"flex"} justifyContent={"right"}>
                <Box>
                    <Link
                        href="#"
                        onClick={handleTermsCondition}
                        className={cssStyle.link}
                    >
                        <Typography
                            className={cssStyle.linkSmall}
                            dangerouslySetInnerHTML={{
                                __html: titleCase(
                                    getLabel(
                                        uiLabels,
                                        "labelTermsConditions",
                                        "Terms & Conditions"
                                    )
                                ),
                            }}
                        ></Typography>
                    </Link>
                </Box>
            </Box>,
        ];

        if (declinedPoints) {
            buttons.unshift(
                <Button
                    key={3}
                    variant="contained"
                    sx={theme.home.login.playButton}
                    onClick={(e) => {
                        handleJoinQueue();
                    }}
                >
                    Join {SUPER_GAME_TEXT}
                </Button>
            );
        }

        return <>{buttons}</>;
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#262626",
                    py: 5,
                }}
            >
                <Box display={"flex"} justifyContent={"center"}>
                    <Box sx={{}}>{showAvatar()}</Box>
                    <Box>
                        {showLoyaltyPoints()}
                        {showScore()}
                        {showAllTimeCorrectAnswer()}
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        mt: 3,
                        px: { xs: 3, md: "11%", lg: "14%", xl: "18%" },
                    }}
                >
                    {showButtons()}
                </Box>
            </Box>
            {guestDialog && (
                <DialogGuest
                    open={guestDialog}
                    closeDialog={() => {
                        setGuestDialog(false);
                    }}
                    uiLabels={uiLabels}
                />
            )}
        </>
    );
}
