const initialState = {
    socket: null,
    active: false
  };
  
  const SocketReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ADD_SOCKET":
        return Object.assign({}, state, {
          socket: action.data.socket,
          active: action.data.active
        });
    
      default:
        return state;
    }
  };

  export const addSocket = (socket, active) => {
    return {
        type: "ADD_SOCKET",
        data: {
            socket,
            active
        }
    };
};

export default SocketReducer;
  