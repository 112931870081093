import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Players from "../Players/Players";
import { useDispatch, useSelector } from "react-redux";
import useSocketEvents from "../../hooks/useSocketEvents";
import {
    GAME_STATUS,
    SOCKET_ACTIONS,
    SOCKET_EVENTS,
} from "../../helpers/constant";
import { getLabel, isCashBattle, putSelfToStart } from "../../helpers/Global";
import { populatePlayers } from "../../reducers/GameReducer";

export default function GameProgress({
    uiLabels,
    me,
    gameStatus,
    requiredPoints,
    isWidget = false,
}) {
    const dispatch = useDispatch();

    const [total, setTotal] = useState(0);

    const [counter, setCounter] = useState(0);

    const [percent, setPercent] = useState(0);

    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    const ogGameDate = useSelector(({ game }) => {
        return game.ogGameDate;
    });

    useEffect(() => {
        if (gameStatus !== GAME_STATUS.END) {
            return;
        }

        setCounter(total);
        setPercent(100);
    }, [gameStatus]);

    const handleQuestionEnds = useCallback(
        (data) => {
            dispatch(
                populatePlayers(putSelfToStart(data.playersStats, me.playerId))
            );
        },
        [dispatch, players]
    );

    // when round ends
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_EVENTS.QUESTION_ENDS,
        null,
        handleQuestionEnds
    );

    // event for total game progress
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_EVENTS.GAME_PROGRESS,
        null,
        (counter, total) => {
            console.log("PROGRESS", counter, total);

            setCounter(counter);
            setTotal(total);

            setPercent((counter / total) * 100);
        }
    );

    // event for next game countdown
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_EVENTS.NEXT_GAME_COUNTDOWN,
        null,
        (count, duration) => {
            setCounter(count);
            setTotal(duration);

            setPercent(((duration - count) / duration) * 100);
        }
    );

    const getProgressText = useCallback(() => {
        switch (gameStatus) {
            case GAME_STATUS.END:
                return `${getLabel(
                    uiLabels,
                    "labelGameRestart",
                    "The game will restart in"
                )} ${total - counter}`;

            default:
                return getLabel(
                    uiLabels,
                    "labelQuestionsLeft",
                    `#0 questions left`
                ).replace(/#0/g, total - counter);
        }
    }, [counter, total, gameStatus]);

    return (
        <Box
            sx={{
                border: isWidget ? "" : `2px solid #00b3e2`,
                p: 1,
                width: "100%",
            }}
        >
            {!isWidget && (
                <Typography
                    variant="h6"
                    sx={{
                        color: "white",
                        textAlign: "center",
                    }}
                >
                    {!isCashBattle(requiredPoints)
                        ? `${getLabel(
                              uiLabels,
                              "labelYouMissed",
                              "Sorry you just missed the game! You can start again after the game is done"
                          )}`
                        : `Game has already started, Join and Catch up!`}
                </Typography>
            )}
            <Players
                players={players}
                forceActive={true}
                questionStart={false}
                isWidget={isWidget}
            />

            <Box>
                <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress variant="determinate" value={percent} />
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                    <Typography
                        variant="body2"
                        sx={{ color: "white", fontSize: { xs: "0.7rem" } }}
                    >
                        {getProgressText()}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
