import { Language } from "@mui/icons-material";
import { GAME_STATUS } from "../helpers/constant";

const initialState = {
    serverStatus: false,
    gamePlayers: [], //list of current players
    gameStatus: GAME_STATUS.WAITING, //status of the game waiting/starting/in-game/end

    roomId: null,

    gameDate: null,

    ogGameDate: null, //original game date when playing a replay game

    relatedVideos: [],
    subCategory: null,
    weeklyLeaderboard: [],
    allTimeLeaderboard: [],

    multiplayerCategory: null,
    multiplayerVideo: null,

    requiredPoints: 0,

    playerAnswerStatus: {},

    nextGameTime: null,

    triviaLivestreams: [], // an array of youtube livesream urls
    gameThumbnail: `${process.env.REACT_APP_WM_URL}/uploads/share-image/placeholder/placeholder-trivia.png`,

    superGame: null,

    notification: "default",

    powerUps: [],

    questions: [],
    questionIndex: 0,
    round: 0,
    language: null,
    languages: [],

    uiLabels: null,

    roundInfo: null, // this will contain all round information

    replayGames: [],
};

const GameReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SERVER_STATUS":
            return Object.assign({}, state, {
                serverStatus: action.data,
            });

        case "UPDATE_GAME_STATUS":
            return Object.assign({}, state, {
                gameStatus: action.data,
            });

        case "UPDATE_ROOM_ID":
            return Object.assign({}, state, {
                roomId: action.data,
            });

        case "POPULATE_PLAYERS":
            return Object.assign({}, state, {
                gamePlayers: action.data,
                playerAnswerStatus: {},
            });

        case "PLAYER_JOINED": {
            //only append if player is not on the list
            if (
                [...state.gamePlayers].find(
                    (player) => player.id == action.data.id
                )
            )
                return state;

            return Object.assign({}, state, {
                gamePlayers: [...state.gamePlayers, action.data],
            });
        }

        case "PLAYER_REMOVED":
            return Object.assign({}, state, {
                gamePlayers: [...state.gamePlayers].filter((player) => {
                    return player.id != action.data;
                }),
            });

        case "PLAYER_RESET": {
            const copy = [...state.gamePlayers].map((player) => {
                action.data.forEach((field) => {
                    player[field] = false;
                });
                return player;
            });

            return Object.assign({}, state, {
                gamePlayers: copy,
                playerAnswerStatus: {},
            });
        }

        case "PLAYER_ANSWERED": {
            const copy = { ...state.playerAnswerStatus };
            copy[action.data] = true;

            return Object.assign({}, state, {
                playerAnswerStatus: copy,
            });
        }

        case "UPDATE_SUBCATEGORY":
            return Object.assign({}, state, {
                subCategory: action.data,
            });

        case "UPDATE_RELATED_VIDEOS":
            return Object.assign({}, state, {
                relatedVideos: action.data,
            });

        case "UPDATE_WEEKLY_LEADERBOARD":
            return Object.assign({}, state, {
                weeklyLeaderboard: action.data,
            });

        case "UPDATE_ALLTIME_LEADERBOARD":
            return Object.assign({}, state, {
                allTimeLeaderboard: action.data,
            });

        case "UPDATE_REQUIRED_POINTS":
            return Object.assign({}, state, {
                requiredPoints: action.data,
            });

        case "UPDATE_GAME_DATE":
            return Object.assign({}, state, {
                gameDate: action.data,
            });

        case "UPDATE_NEXT_GAME_TIME":
            return Object.assign({}, state, {
                nextGameTime: action.data,
            });

        case "UPDATE_TRIVIA_LIVESTREAMS":
            return Object.assign({}, state, {
                triviaLivestreams: action.data,
            });

        case "UPDATE_GAME_THUMBNAIL":
            return Object.assign({}, state, {
                gameThumbnail: action.data,
            });

        case "UPDATE_SUPER_GAME_INFO":
            return Object.assign({}, state, {
                superGame: action.data,
            });

        case "UPDATE_NOTIFICAION":
            return Object.assign({}, state, {
                notification: action.data,
            });

        case "ADD_POWER_UPS":
            return Object.assign({}, state, {
                powerUps: action.data,
            });

        case "UPDATE_QUESTIONS":
            return Object.assign({}, state, {
                questions: action.data,
            });

        case "UPDATE_QUESTION_INDEX":
            return Object.assign({}, state, {
                questionIndex: action.data,
            });

        case "UPDATE_ROUND":
            return Object.assign({}, state, {
                round: action.data,
            });

        case "UPDATE_LANGUAGES":
            return Object.assign({}, state, {
                languages: action.data,
            });

        case "UPDATE_LANGUAGE":
            return Object.assign({}, state, {
                language: action.data,
            });

        case "UPDATE_UI_LABELS":
            return Object.assign({}, state, {
                uiLabels: action.data,
            });

        case "UPDATE_MULTIPLAYER_CATEGORY":
            return Object.assign({}, state, {
                multiplayerCategory: action.data,
            });

        case "UPDATE_MULTIPLAYER_VIDEO":
            return Object.assign({}, state, {
                multiplayerVideo: action.data,
            });

        case "UPDATE_ROUND_INFO":
            return Object.assign({}, state, {
                roundInfo: action.data,
            });

        case "UPDATE_OG_GAME_DATE":
            return Object.assign({}, state, {
                ogGameDate: action.data,
            });

        case "ADD_REPLAY_GAMES":
            return Object.assign({}, state, {
                replayGames: action.data,
            });

        default:
            return state;
    }
};

//adding server status
export const serverStatus = (status) => {
    return {
        type: "SERVER_STATUS",
        data: status,
    };
};

//adding game status
export const updateGameStatus = (gameStatus) => {
    return {
        type: "UPDATE_GAME_STATUS",
        data: gameStatus,
    };
};

//adding which room the player is in
export const updateRoomId = (roomId) => {
    return {
        type: "UPDATE_ROOM_ID",
        data: roomId,
    };
};

//populate players
export const populatePlayers = (players) => {
    return {
        type: "POPULATE_PLAYERS",
        data: players,
    };
};

//add player
export const playerJoined = (player) => {
    return {
        type: "PLAYER_JOINED",
        data: player,
    };
};

//remove player
export const playerRemoved = (playerId) => {
    return {
        type: "PLAYER_REMOVED",
        data: playerId,
    };
};

//player reset, method to reset player's didAnswer, isCorrect, and isLeader
export const playerReset = (
    playerFields = ["didAnswer", "isCorrect", "isLeader"]
) => {
    return {
        type: "PLAYER_RESET",
        data: playerFields,
    };
};

//player answered
export const playerAnswered = (playerId) => {
    return {
        type: "PLAYER_ANSWERED",
        data: playerId,
    };
};

//update subcategory
export const updateSubcategory = (subCategory) => {
    return {
        type: "UPDATE_SUBCATEGORY",
        data: subCategory,
    };
};

//update related videos
export const updateRelatedVideos = (relatedVideos) => {
    return {
        type: "UPDATE_RELATED_VIDEOS",
        data: relatedVideos,
    };
};

//update weekly leaderboard
export const updateWeeklyLeaderboard = (leaderboard) => {
    return {
        type: "UPDATE_WEEKLY_LEADERBOARD",
        data: leaderboard,
    };
};

//update weekly leaderboard
export const updateAllTimeLeaderboard = (leaderboard) => {
    return {
        type: "UPDATE_ALLTIME_LEADERBOARD",
        data: leaderboard,
    };
};

//update game required points
export const updateGameRequiredPoints = (points = 0) => {
    return {
        type: "UPDATE_REQUIRED_POINTS",
        data: points,
    };
};

//update game date
export const updateGameDate = (date) => {
    return {
        type: "UPDATE_GAME_DATE",
        data: date,
    };
};

//update game time
export const updateNextGameTime = (nextGameTime) => {
    return {
        type: "UPDATE_NEXT_GAME_TIME",
        data: nextGameTime,
    };
};

//update game trivia livestream
export const updateTriviaLivestreams = (triviaLivestreams) => {
    return {
        type: "UPDATE_TRIVIA_LIVESTREAMS",
        data: triviaLivestreams,
    };
};

//update game thumbnail
export const updateGameThumbnail = (thumbnail) => {
    return {
        type: "UPDATE_GAME_THUMBNAIL",
        data: thumbnail,
    };
};

export const updateSuperGameInfo = (superGame) => {
    return {
        type: "UPDATE_SUPER_GAME_INFO",
        data: superGame,
    };
};

export const updateNotification = (flag) => {
    return {
        type: "UPDATE_NOTIFICAION",
        data: flag,
    };
};

export const addPowerUps = (powerUps) => {
    return {
        type: "ADD_POWER_UPS",
        data: powerUps,
    };
};

export const updateQuestions = (questions) => {
    return {
        type: "UPDATE_QUESTIONS",
        data: questions,
    };
};

export const updateQuestionIndex = (index) => {
    return {
        type: "UPDATE_QUESTION_INDEX",
        data: index,
    };
};

export const updateRound = (round) => {
    return {
        type: "UPDATE_ROUND",
        data: round,
    };
};

export const updateLanguages = (languages) => {
    return {
        type: "UPDATE_LANGUAGES",
        data: languages,
    };
};

export const updateLanguage = (language) => {
    return {
        type: "UPDATE_LANGUAGE",
        data: language,
    };
};

export const updateUILabels = (uiLabels) => {
    return {
        type: "UPDATE_UI_LABELS",
        data: uiLabels,
    };
};

export const updateMultiplayerCategory = (data) => {
    return {
        type: "UPDATE_MULTIPLAYER_CATEGORY",
        data: data,
    };
};

export const updateMultiplayerVideo = (data) => {
    return {
        type: "UPDATE_MULTIPLAYER_VIDEO",
        data: data,
    };
};

export const updateRoundInfo = (data) => {
    return {
        type: "UPDATE_ROUND_INFO",
        data: data,
    };
};

export const updateOgGameDate = (ogGameDate) => {
    return {
        type: "UPDATE_OG_GAME_DATE",
        data: ogGameDate,
    };
};

export const addReplayGames = (data) => {
    return {
        type: "ADD_REPLAY_GAMES",
        data: data,
    };
};

export default GameReducer;
