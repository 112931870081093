const initialState = {
    startingTimer: null,
    playTimer: null,
};

const TimerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_STARTING_TIMER":
            return Object.assign({}, state, {
                startingTimer: action.data,
            });

        case "ADD_PLAY_TIMER":
            return Object.assign({}, state, {
                playTimer: action.data,
            });

        default:
            return state;
    }
};

// HANDLE STARTING TIMER
export const addStartTimer = (timer) => {
    return {
        type: "ADD_STARTING_TIMER",
        data: timer,
    };
};

// HANDLE PLAY TIMER
export const addPlayTimer = (timer) => {
    return {
        type: "ADD_PLAY_TIMER",
        data: timer,
    };
};

export default TimerReducer;
