import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        wmColor: { main: "#00b3e2" },
        textColor: { main: "#fafafa", faded: "lightgrey" },
        backgroundColor: { main: "#181818" },
        gameModeActive: { main: "#2B235C" },
        secondaryButton: { main: "#3f3f3f" },
        voteButton: { main: "#E5E5E5" },
        hoverColor: { main: "#5c636a" },
        correct: { main: "green" },
        inCorrect: { main: "red" },
        disabled: { main: "#444444" },
        borderColor: { gameOverGreen: "#40F0BC" },
    },

    font: {
        size: {
            playerStats: {
                xs: "1.05rem",
                sm: "1.75rem",
                md: "2rem",
            },
            playerStatsLabel: {
                xs: "0.7rem",
                sm: "0.8rem",
                md: "1rem",
            },
        },
        family: `"Bangers", system-ui !important`,
    },
    space: {
        home: { px: { xs: 3, md: "20%", lg: "25%" } },
    },
    borderRadius: {
        primary: "8px",
        secondary: "4px",
    },
    pLeft: {
        main: "8px",
        secondary: "15px",
    },
    pRight: {
        main: "8px",
        secondary: "15px",
    },
    px8: {
        main: "8px",
    },
    px10: {
        main: "10px",
    },
    px16: {
        main: "16px",
    },
    categoryButtons: {
        px: { xs: "8px", md: "20%" },
    },
    howTo: {
        imageSize: { xs: "90px", md: "120px" },
        container: { mobile: 200 },
    },
    mainContainer: {
        paddingLeft: "20%",
        paddingRight: "20%",
        height: { xs: "auto", md: "100vh" },
    },
    height: {
        vh100: "100vh",
        h100: "100%",
    },
    player: {
        winnerColor: { 1: "#00b3e2", 2: "#ef4eec", 3: "#16f5b3" },
    },
    play: {
        buttonAnswer: {
            fontSize: { xs: "1.2rem", md: "1.3rem" },
            fontSizeSM: { xs: "1rem", md: "1rem" },
        },
    },
    home: {
        countdownHeader: {
            textAlign: { xs: "center", md: "left" },
        },
        timer: { bg: { active: "rgb(0, 180, 00)", inactive: "#212121" } },
        login: {
            bgColor: { dialog: "#212121", desktop: "#1A1A1A" },
            checkIcon: { color: "#16F5B3", marginRight: "16px" },
            listItem: { padding: "8px 0px" },
            listItemFont: { fontWeight: 400, fontSize: "16px" },
            listItemFontMoney: {
                color: "#16F5B3",
                fontWeight: 800,
                fontSize: "16px",
            },
            playerAvatar: {
                mx: "auto",
                border: { xs: 2, md: 4 },
                borderColor: `#00b3e2 !important`,
                width: { xs: 60, sm: 90, md: 130, lg: 150 },
                height: { xs: 60, sm: 90, md: 130, lg: 150 },
                backgroundColor: "#8B8B8B",
            },
            qualifyButton: {
                fontSize: { xs: "12px", sm: "14px" },
                width: "100%",
                backgroundColor: "#00b3e2 !important",
                mb: 1,
            },
            redeemButton: {
                fontSize: { xs: "12px", sm: "14px" },
                width: "100%",
                backgroundColor: "#09c !important",
                mb: 1,
            },
            termButton: {
                fontSize: { xs: "12px", sm: "14px" },
                width: "100%",
                backgroundColor: "#3F3F3F !important",
                mb: 1,
            },
            topicPlayButton: {
                fontSize: { xs: "12px", sm: "16px" },
                width: "100%",
                backgroundColor: "#00b3e2 !important",
            },
            topicWatchButton: {
                fontSize: { xs: "12px", sm: "16px" },
                width: "100%",
                backgroundColor: "#424242 !important",
            },
            inviteButton: {
                fontSize: { xs: "12px", sm: "14px" },
                width: { xs: "100%" },
                backgroundColor: "#00b3e2 !important",
                mb: 1,
            },
            playButton: {
                fontSize: { xs: "12px", sm: "14px" },
                width: "100%",
                backgroundColor: "rgb(0, 180, 00) !important",
                mb: 1,
            },
            joinCatchUpButton: {
                fontSize: { xs: "12px", sm: "14px" },
                width: { xs: "auto", md: "80%" },
                backgroundColor: "rgb(0, 180, 00) !important",
                mb: 1,
            },
            guestButton: {
                fontSize: { xs: "16px", md: "12px" },
                width: "100%",
                backgroundColor: "#00b3e2 !important",
            },
            signInButton: {
                borderRadius: 0,
                fontSize: { xs: "16px", md: "18px" },
                height: { sx: "auto", md: "100%" },
                width: "300px",
                backgroundColor: "#09c; !important",
                fontWeight: 600,
                "&:hover": {
                    backgroundColor: "#00b3e2 !important",
                },
            },
            googleButton: {
                fontSize: { xs: "16px", md: "12px" },
                width: "100%",
                backgroundColor: "#CD5542 !important",
            },
            facebookButton: {
                fontSize: { xs: "16px", md: "12px" },
                width: "100%",
                backgroundColor: "#415A93 !important",
            },
        },
        topic: {
            tab: { color: "rgba(255, 255, 255, 0.7);" },
        },
        leaderboard: {
            tdIndex: {
                color: "white",
                fontWeight: 900,
                textAlign: "center",
                width: "40px",
            },
            tdPlayer: {
                paddingTop: 0,
                paddingBottom: 0,
                color: "#FFFFFF",
            },
        },
    },
});
