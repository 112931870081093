const initialState = {
    playerId: null,
    playerName: null,
    playerAvatar: null,
    playerScore: 0,
    playerQueue: null,
    playerJoin: false,
    playerRedeem: false,
    playerStats: null,
    inviteCode: null,
    hasConsent: false,
    spectator: false,
    points: 0,
    usedPowerUps: [], //list of power ups player used
};

const PlayerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SAVE_YOUR_PROFILE":
            return Object.assign({}, state, {
                ...state,
                playerId: action.data.playerId,
                playerName: action.data.playerName,
                playerAvatar: action.data.playerAvatar,
                playerScore: action.data.playerScore,
                playerQueue: action.data.playerQueue,
                playerJoin: action.data.playerJoin,
                playerRedeem: action.data.playerRedeem,
                inviteCode: !isNaN(action.data.playerId)
                    ? action.data.inviteCode
                        ? action.data.inviteCode
                        : null
                    : null,
                hasConsent: action.data.hasConsent,
                spectator: action.data.spectator,
            });

        case "UPDATE_YOU_JOIN":
            return Object.assign({}, state, {
                ...state,
                playerJoin: action.data,
            });

        case "UPDATE_PLAYER_STATS":
            return Object.assign({}, state, {
                ...state,
                playerStats: action.data,
            });

        case "UPDATE_IS_SPECTATOR":
            return Object.assign({}, state, {
                ...state,
                spectator: action.data,
            });

        case "UPDATE_POINTS":
            return Object.assign({}, state, {
                ...state,
                points: action.data,
            });

        case "UPDATE_USED_POWER_UPS":
            return Object.assign({}, state, {
                ...state,
                usedPowerUps: action.data,
            });

        default:
            return state;
    }
};

//adding the player details
export const saveYourProfile = (
    playerId,
    playerName,
    playerAvatar,
    playerScore = 0,
    playerQueue,
    playerJoin,
    playerRedeem,
    inviteCode,
    hasConsent = false,
    spectator = false
) => {
    return {
        type: "SAVE_YOUR_PROFILE",
        data: {
            playerId,
            playerName,
            playerAvatar,
            playerScore,
            playerQueue,
            playerJoin,
            playerRedeem,
            inviteCode,
            hasConsent,
            spectator,
        },
    };
};

//adding the player details
export const joinRoom = (joined) => {
    return {
        type: "UPDATE_YOU_JOIN",
        data: joined,
    };
};

//update player stats
export const updatePlayerStats = (playerStats) => {
    return { type: "UPDATE_PLAYER_STATS", data: playerStats };
};

//update player spectator
export const updateIsSpectator = (value) => {
    return { type: "UPDATE_IS_SPECTATOR", data: value };
};

export const updatePoints = (points) => {
    return { type: "UPDATE_POINTS", data: points };
};

export const updateUsedPowerUps = (player) => {
    return {
        type: "UPDATE_USED_POWER_UPS",
        data: player && player.usedPowerUps ? player.usedPowerUps : [],
    };
};

export default PlayerReducer;
