import React, { useEffect } from "react";

import { Typography, Box, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import PlayerEndSreen from "../PlayerEndScreen/PlayerEndSreen";
import styles from "./PlayersEndScreen.module.css";
import { getLabel } from "../../helpers/Global";

export default function PlayersEndScreen({
    showLeaderboardTitle = true,
    uiLabels,
}) {
    const theme = useTheme();
    const players = useSelector(({ game }) => game.gamePlayers);
    const me = useSelector(({ player }) => player);

    return (
        <>
            {players.length > 0 && (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Box
                        sx={{
                            width: { xs: "100%" },
                        }}
                    >
                        {showLeaderboardTitle && (
                            <Typography
                                component="h5"
                                variant="h5"
                                align="center"
                                sx={{
                                    display: { xs: "block", md: "block" },
                                    pb: "6px",
                                    letterSpacing: 2,
                                }}
                            >
                                {getLabel(
                                    uiLabels,
                                    "labelLeaderboard",
                                    "Leaderboard"
                                )}
                            </Typography>
                        )}
                        <Box
                            className={styles.playersContainer}
                            sx={{
                                maxHeight: "500px",
                                overflowY: "auto",
                            }}
                        >
                            <Grid container>
                                {players.map((player, index) => {
                                    return (
                                        <PlayerEndSreen
                                            key={index}
                                            index={index}
                                            player={player}
                                            uiLabels={uiLabels}
                                        />
                                    );
                                })}
                                {/* if 'me' is not in top5 then add 1 more PlayerEndSreen for 'me' */}
                                {/* {players.map((player, index) => {
                            if(me.playerId === player.id && index > 5){
                                return (
                                    <PlayerEndSreen
                                        key={index}
                                        index={index}
                                        player={player}
                                    />
                                );
                            }
                        })} */}
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
}
