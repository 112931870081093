import React, { useState } from "react";

import {
    Avatar,
    Box,
    CardHeader,
    Grid,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@mui/material";

import cssStyle from "./HomeLeaderboard.module.css";
import {
    completeLeaderboard,
    formatNumber,
    getLabel,
} from "../../helpers/Global";
import { useTheme } from "@mui/material/styles";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import StarIcon from "@mui/icons-material/Star";

export default function HomeLeaderboard({
    leaderboard,
    showAvatar = true,
    showHeader = false,
    ptsInline = true,
    startPosition = 0,
    isEmpty = false,
    player,
    uiLabels,
}) {
    const theme = useTheme();

    leaderboard = completeLeaderboard(leaderboard, 10);

    function Trophy(props) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ position: "relative" }}
            >
                <EmojiEventsIcon
                    sx={{ fontSize: "5rem", color: theme.palette.wmColor.main }}
                />
                <StarIcon className={cssStyle.starCSS} />
            </Box>
        );
    }

    return (
        <Box sx={{ position: "relative" }}>
            <Table size="small">
                {showHeader && (
                    <TableHead>
                        <TableRow>
                            <TableCell className={`${cssStyle.tdHeader}`}>
                                {getLabel(uiLabels, "labelRank", "RANK")}
                            </TableCell>
                            <TableCell className={`${cssStyle.tdHeader}`}>
                                {getLabel(uiLabels, "labelPlayer", "PLAYER")}
                            </TableCell>
                            <TableCell
                                align="center"
                                className={`${cssStyle.tdHeader}`}
                                sx={{ width: "60px" }}
                            >
                                {getLabel(
                                    uiLabels,
                                    "labelCorrectAnswer",
                                    "CORRECT ANSWERS"
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                )}

                <TableBody>
                    {leaderboard.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell
                                className={`${cssStyle.tdBorder} ${
                                    cssStyle.tdBorderIndexBG
                                } ${
                                    player &&
                                    row.id &&
                                    row.id == player.playerId
                                        ? cssStyle.tdPlayerPositionStart
                                        : ""
                                }`}
                                sx={theme.home.leaderboard.tdIndex}
                            >
                                {row.position
                                    ? row.position
                                    : index + startPosition + 1}
                            </TableCell>
                            <TableCell
                                className={`${cssStyle.tdBorder} ${
                                    player &&
                                    row.id &&
                                    row.id == player.playerId
                                        ? cssStyle.tdPlayerPosition
                                        : ""
                                }`}
                                sx={theme.home.leaderboard.tdPlayer}
                            >
                                <CardHeader
                                    sx={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        "& .MuiTypography-root": {
                                            "-webkitLineClamp": "1",
                                            display: "-webkit-box",
                                            "-webkitBoxOrient": "vertical",
                                            overflow: "hidden",
                                            fontWeight: 400,
                                            color: "#FFFFFF",
                                            fontSize: "14px",
                                        },
                                    }}
                                    avatar={
                                        !showAvatar ? (
                                            ""
                                        ) : row.isBlank ? (
                                            <Avatar
                                                className={cssStyle.avatarCSS}
                                                sx={{
                                                    display: showAvatar
                                                        ? "flex"
                                                        : {
                                                              xs: "flex",
                                                              md: "none",
                                                          },
                                                }}
                                            >
                                                {row.avatar}
                                            </Avatar>
                                        ) : (
                                            <Avatar
                                                className={cssStyle.avatarCSS}
                                                alt={row.name}
                                                src={`${row.avatar}`}
                                                sx={{
                                                    display: showAvatar
                                                        ? "flex"
                                                        : {
                                                              xs: "flex",
                                                              md: "none",
                                                          },
                                                }}
                                            />
                                        )
                                    }
                                    title={row.name}
                                />
                            </TableCell>
                            <TableCell
                                className={`${cssStyle.tdBorder} ${
                                    player &&
                                    row.id &&
                                    row.id == player.playerId
                                        ? cssStyle.tdPlayerPositionEnd
                                        : ""
                                }`}
                            >
                                <Typography
                                    noWrap
                                    variant={ptsInline ? "caption" : "body1"}
                                    sx={{
                                        fontWeight: 400,
                                        color: "#FFFFFF",
                                        fontSize: "14px",
                                        textAlign: ptsInline
                                            ? "right"
                                            : "center",
                                    }}
                                >
                                    {formatNumber(row.correctAnswer)}
                                </Typography>{" "}
                                {/* <Typography
                                    noWrap
                                    variant={ptsInline ? "caption" : "body1"}
                                    sx={{
                                        fontWeight: 400,
                                        color: "#FFFFFF",
                                        fontSize: "14px",
                                        textAlign: ptsInline
                                            ? "right"
                                            : "center",
                                        textTransform: ptsInline
                                            ? "none"
                                            : "uppercase",
                                    }}
                                >
                                    pts
                                </Typography> */}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {isEmpty && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        backgroundColor: "rgba(48, 48, 50, 0.8)",
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={4}>
                            <Trophy />
                        </Grid>
                        <Grid item xs={8}>
                            <Box>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: theme.palette.wmColor.main,
                                        fontSize: "17px",
                                        fontWeight: 800,
                                    }}
                                >
                                    Nobody Here!
                                </Typography>

                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: theme.palette.textColor.main,
                                        fontSize: "15px",
                                        fontWeight: 500,
                                    }}
                                >
                                    Start playing and be the first!
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    );
}
