import React from "react";
import { getLabel } from "../../helpers/Global";
import { List, ListItem, Typography } from "@mui/material";
import cssStyle from "./WhatAreMojoPoints.module.css";
import { coin } from "../../helpers/constant";

export default function WhatAreMojoPointsContent({ uiLabels }) {
    return (
        <>
            <Typography
                paragraph
                className={`${cssStyle.textWhite}`}
                dangerouslySetInnerHTML={{
                    __html: `${getLabel(
                        uiLabels,
                        "whatAreMojoPointsInfo1",
                        "MojoPoints are your ticket to exciting Cash Battles, where you can compete for real cash prizes"
                    ).replace(/#0/g, `${coin}&nbsp;`)}`,
                }}
            ></Typography>

            <Typography
                sx={{ color: `white`, mt: 2 }}
                dangerouslySetInnerHTML={{
                    __html: `${getLabel(
                        uiLabels,
                        "whatAreMojoPointsInfo2",
                        "Sign in and earn MojoPoints by"
                    ).replace(/#0/g, `${coin}&nbsp;`)}:`,
                }}
            ></Typography>

            <List className={cssStyle.list} sx={{ mt: 2 }}>
                <ListItem
                    key={`key-0`}
                    sx={{
                        display: "list-item",
                        color: "white",
                        m: 0,
                        p: 0,
                    }}
                >
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: `<strong>${getLabel(
                                uiLabels,
                                "whatAreMojoPoints_PointsBattle",
                                "Playing Daily Points Battles"
                            )}:</strong> ${getLabel(
                                uiLabels,
                                "whatAreMojoPoints_PointsBattleSub",
                                "Supercharge your points once per day"
                            )}.`,
                        }}
                    ></Typography>
                </ListItem>

                <ListItem
                    key={`key-1`}
                    sx={{
                        display: "list-item",
                        color: "white",
                        m: 0,
                        p: 0,
                    }}
                >
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: `<strong>${getLabel(
                                uiLabels,
                                "whatAreMojoPoints_MultiplayerGame",
                                "Playing Multiplayer Games"
                            )}:</strong> ${getLabel(
                                uiLabels,
                                "whatAreMojoPoints_MultiplayerGameSub",
                                "Play anytime and earn points at a steady pace"
                            )}.`,
                        }}
                    ></Typography>
                </ListItem>

                <ListItem
                    key={`key-2`}
                    sx={{
                        display: "list-item",
                        color: "white",
                        m: 0,
                        p: 0,
                    }}
                >
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: `<strong>${getLabel(
                                uiLabels,
                                "whatAreMojoPoints_AnswerStreaks",
                                "Answer Streaks"
                            )}:</strong> ${getLabel(
                                uiLabels,
                                "whatAreMojoPoints_AnswerStreaksSub",
                                "Earn points for correct answer streaks"
                            )}.`,
                        }}
                    ></Typography>
                </ListItem>

                <ListItem
                    key={`key-3`}
                    sx={{
                        display: "list-item",
                        color: "white",
                        m: 0,
                        p: 0,
                    }}
                >
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: `<strong>${getLabel(
                                uiLabels,
                                "whatAreMojoPoints_ParticipationStreak",
                                "Participation Streaks"
                            )}:</strong> ${getLabel(
                                uiLabels,
                                "whatAreMojoPoints_ParticipationStreakSub",
                                "Get rewarded for playing consistently"
                            )}.`,
                        }}
                    ></Typography>
                </ListItem>

                <ListItem
                    key={`key-4`}
                    sx={{
                        display: "list-item",
                        color: "white",
                        m: 0,
                        p: 0,
                    }}
                >
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: `<strong>${getLabel(
                                uiLabels,
                                "whatAreMojoPoints_InvitingFriends",
                                "'Inviting Friends"
                            )}:</strong> ${getLabel(
                                uiLabels,
                                "whatAreMojoPoints_InvitingFriendsSub",
                                "Earn points when your friends join the fun"
                            )}.`,
                        }}
                    ></Typography>
                </ListItem>
            </List>

            <Typography
                sx={{ mt: 3, color: "white" }}
                dangerouslySetInnerHTML={{
                    __html: `${getLabel(
                        uiLabels,
                        "whatAreMojoPointsInfo3",
                        "Redeem MojoPoints to join Cash Battles and win big!"
                    ).replace(/#0/g, `${coin}&nbsp;`)}`,
                }}
            ></Typography>
        </>
    );
}
