import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLabel, login } from "../../helpers/Global";
import { GAME_STATUS, SOCKET_EVENTS } from "../../helpers/constant";

import css from "./OnlinePlayers.module.css";
import { ViewportList } from "react-viewport-list";
import { availableOL } from "../../reducers/OnlinePlayersReducer";

export default function OnlinePlayers({
    me,
    uiLabels,
    gameStatus,
    socketFromStore,
}) {
    const dispatch = useDispatch();

    const onlinePlayers = useSelector(
        ({ onlinePlayers }) => onlinePlayers.players
    );

    const [filteredOnlinePlayers, setFilteredOnlinePlayers] = useState([]);

    const playersContainer = useRef();
    const defaultSX = {
        position: "relative",
        width: { xs: "100%" },
        overflowX: "auto",
        px: 1,
    };

    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    const handleChallenge = (player) => {
        if (!me.playerId) {
            login();
            return;
        }

        socketFromStore.emit(SOCKET_EVENTS.CHALLENGE, player.playerId);
    };

    const handleScroll = (evt) => {
        if (!playersContainer) return;

        evt.preventDefault();
        playersContainer.current.scrollLeft += evt.deltaY;
    };

    useEffect(() => {
        if (!playersContainer || !playersContainer.current) return;

        playersContainer.current.addEventListener("wheel", handleScroll);

        return () => {
            if (!playersContainer || !playersContainer.current) return;

            playersContainer.current.removeEventListener("wheel", handleScroll);
        };
    }, []);

    useEffect(() => {
        const online = [...onlinePlayers].filter((player) => {
            if (player.playerId == me.playerId) {
                return false;
            }

            if (
                players.find((p) => {
                    return p.id == player.playerId;
                }) &&
                gameStatus &&
                gameStatus !== GAME_STATUS.WAITING
            ) {
                return false;
            }

            return true;
        });
        dispatch(availableOL(online.length));
        setFilteredOnlinePlayers(online);
    }, [onlinePlayers, me, players, gameStatus]);

    return (
        <Box
            ref={playersContainer}
            className="thinScroll"
            sx={defaultSX}
            display={"flex"}
        >
            <ViewportList
                items={filteredOnlinePlayers}
                itemMinSize={5}
                margin={0}
                axis="x"
            >
                {(player, index) => {
                    return (
                        <Box
                            key={index}
                            sx={{
                                m: 0,
                                justifyContent: "center",
                            }}
                        >
                            <Box>
                                <Box
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    <Avatar
                                        alt={`${player.playerAvatar}`}
                                        src={`${player.playerAvatar}`}
                                        sx={{ width: "26px", height: "26px" }}
                                    />

                                    <Typography
                                        noWrap
                                        variant="body1"
                                        sx={{
                                            fontSize: {
                                                xs: "0.7rem",
                                                md: "0.8rem",
                                            },
                                            width: "100px",
                                            textAlign: "center",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {player.playerName}
                                    </Typography>
                                </Box>

                                <Button
                                    variant="text"
                                    sx={{
                                        width: "100%",
                                        fontSize: "0.65rem",
                                        "&:disabled": {
                                            color: "gray",
                                        },
                                        pt: "0px",
                                    }}
                                    onClick={() => {
                                        handleChallenge(player);
                                    }}
                                    disabled={player.challenged ? true : false}
                                >
                                    {player.challenged
                                        ? "CHALLENGED"
                                        : "CHALLENGE"}
                                </Button>
                            </Box>
                        </Box>
                    );
                }}
            </ViewportList>
        </Box>
    );
}
