import React, { useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import { GAME_STATUS } from "../../helpers/constant";
import ButtonJoin from "../Button/ButtonJoin";
import { getLabel, isCashBattle } from "../../helpers/Global";

export default function CountdownHeader({
    requiredPoints,
    gameStatus = GAME_STATUS.WAITING,
    player = null,
    textHeader = null,
    css,
    questionIndex,
    round,
    uiLabels,
}) {
    const gameAlreadyTackledMessage = useCallback(() => {
        //return blank if player is not logged in
        if (!player.playerId) {
            return (
                <Box>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: { xs: "20px", md: "20px" },
                            fontWeight: 700,
                            width: "100%",
                            textAlign: !css
                                ? { xs: "center", md: "center" }
                                : css.textAlign,
                        }}
                    >
                        {`${getLabel(
                            uiLabels,
                            "labelGameStartsIn",
                            "Game Starts In"
                        )}:`}
                    </Typography>
                </Box>
            );
        }

        return (
            <Box>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: "20px", md: "20px" },
                        fontWeight: 700,
                        width: "100%",
                        textAlign: { xs: "center", md: "center" },
                    }}
                >
                    {`${getLabel(
                        uiLabels,
                        "message1Game",
                        `You've already tackled today's Points Battle! Check back tomorrow for another chance to win big!`
                    )}!`}
                </Typography>
            </Box>
        );
    }, [player]);

    function showHeader() {
        let header =
            isCashBattle(requiredPoints) || textHeader ? (
                <Box>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: { xs: "20px", md: "20px" },
                            fontWeight: 700,
                            width: "100%",
                            textAlign: !css
                                ? { xs: "center", md: "center" }
                                : css.textAlign,
                        }}
                    >
                        {textHeader === null
                            ? `${getLabel(
                                  uiLabels,
                                  "labelGameStartsIn",
                                  "Game Starts In"
                              )}:`
                            : textHeader}
                    </Typography>
                </Box>
            ) : (
                <></>
            );

        if (textHeader !== null) return header;

        // waiting, started, full
        switch (gameStatus) {
            case GAME_STATUS.COUNTDOWN:
                header = player.playerQueue ? (
                    <Box>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: "20px", md: "20px" },
                                fontWeight: 700,
                                width: "100%",
                                textAlign: { xs: "center", md: "center" },
                            }}
                        >
                            {`${getLabel(
                                uiLabels,
                                "labelGameStarted",
                                "Game Started"
                            )}!`}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: "rgb(0, 180, 00)",
                                fontSize: "30px",
                                fontWeight: 900,
                                width: "100%",
                                textAlign: { xs: "center", md: "center" },
                                lineHeight: 1,
                            }}
                        >
                            {`${getLabel(
                                uiLabels,
                                "labelLetsPlay",
                                "Let's Play"
                            )}!`}
                        </Typography>
                        <Box
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{
                                width: "100%",
                                mt: 1,
                            }}
                        >
                            <ButtonJoin
                                player={player}
                                gameStatus={gameStatus}
                                uiLabels={uiLabels}
                                label={getLabel(
                                    uiLabels,
                                    "btnJoinGame",
                                    "Join Game"
                                )}
                            />
                        </Box>
                    </Box>
                ) : (
                    gameAlreadyTackledMessage()
                );
                break;

            case GAME_STATUS.QUESTIONS:
            case GAME_STATUS.ROUND_ENDING:
            case GAME_STATUS.ROUND_WAITING:
                header = (
                    <Box>
                        {(isCashBattle(requiredPoints) ||
                            player.playerJoin) && (
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <ButtonJoin
                                    player={player}
                                    gameStatus={gameStatus}
                                    uiLabels={uiLabels}
                                    label={
                                        !player.playerJoin
                                            ? getLabel(
                                                  uiLabels,
                                                  "btnJoinCatchUp",
                                                  "Join Game & Catch Up!"
                                              )
                                            : getLabel(
                                                  uiLabels,
                                                  "btnBackToGame",
                                                  "Back to game"
                                              )
                                    }
                                />
                            </Box>
                        )}
                    </Box>
                );
                break;

            default:
                if (player.playerId && player.playerQueue === null) {
                    header = (
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "20px", md: "20px" },
                                    fontWeight: 700,
                                    width: "100%",
                                    textAlign: { xs: "center", md: "center" },
                                }}
                            >
                                {`${getLabel(
                                    uiLabels,
                                    "labelNoSpotsAvailable",
                                    "Sorry, no spots available"
                                )}!`}
                            </Typography>
                        </Box>
                    );
                }

                if (!player.playerQueue) {
                    header = gameAlreadyTackledMessage();
                }

                break;
        }
        return header;
    }
    return <>{showHeader()}</>;
}
