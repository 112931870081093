import { Grid, Typography } from "@mui/material";
import React from "react";
import ActiveGame from "../ActiveGame/ActiveGame";

export default function ActiveGames({
    header = "Category",
    uiLabels,
    games = [],
    gridSize = { xs: 12, md: 4 },
    theme,
}) {
    return (
        <>
            {games && games.length > 0 && (
                <>
                    <Typography
                        variant="h5"
                        sx={{ letterSpacing: 2, fontFamily: theme.font.family }}
                    >
                        {header}
                    </Typography>
                    <Grid container spacing={1}>
                        {games.map((game) => {
                            return (
                                <Grid
                                    key={game.roomId}
                                    item
                                    xs={gridSize.xs}
                                    md={gridSize.md}
                                >
                                    <ActiveGame
                                        game={game}
                                        uiLabels={uiLabels}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}
        </>
    );
}
