import { Box, Button, Typography } from "@mui/material";
import { React, useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import cssStyle from "./InputAnswer.module.css";
import DoneIcon from "@mui/icons-material/Done";

export default function InputAnswer({
    style = {},
    buttonBackgroundColor,
    correctAnswer,
    handleAnswerClick,
    questionStart,
    spectator = false,
}) {
    const theme = useTheme();
    const inputAnswser = useRef();
    const inputForm = useRef();

    const [answerDone, setAnswerDone] = useState(false);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!inputAnswser.current) return;

        if (inputAnswser.current.value.trim() === "") return;

        setAnswerDone(true);
        handleAnswerClick(inputAnswser.current.value.trim());
        return false;
    };

    useEffect(() => {
        if (questionStart && inputForm && inputForm.current) {
            inputForm.current.reset();
            setAnswerDone(false);
        }
    }, [questionStart]);

    return (
        <>
            <Box
                display={correctAnswer ? "flex" : "none"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                    backgroundColor:
                        correctAnswer === ""
                            ? theme.palette.correct.main
                            : buttonBackgroundColor,
                    borderRadius: theme.borderRadius.secondary,
                    padding: 2,
                    opacity: correctAnswer === "" ? "0.5" : "1",
                    filter:
                        correctAnswer === ""
                            ? "brightness(1.2)"
                            : "brightness(1)",
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        color: `${theme.palette.textColor.main} !important`,
                        fontWeight: { xs: "500", md: "600" },
                        textTransform: "capitalize",
                        lineHeight: "1",
                        fontSize: { xs: "1.1rem", md: "1.3rem" },
                    }}
                >
                    <span
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                        }}
                    >
                        {correctAnswer}
                    </span>
                    {questionStart === false && correctAnswer === "" ? (
                        <DoneIcon
                            sx={{
                                fontWeight: "700",
                                verticalAlign: "middle",
                                ml: 3,
                            }}
                        />
                    ) : (
                        ""
                    )}
                </Typography>
            </Box>
            {!spectator && (
                <form ref={inputForm} onSubmit={handleFormSubmit}>
                    <Box
                        sx={{ display: { xs: "block", md: "flex" } }}
                        style={style}
                    >
                        <input
                            ref={inputAnswser}
                            className={cssStyle.answerBox}
                            autofocus
                            placeholder="Type your answer"
                            disabled={
                                !questionStart || answerDone ? true : false
                            }
                        ></input>

                        <Button
                            type="submit"
                            className={cssStyle.btnSubmitAnswer}
                            variant="contained"
                            sx={{
                                backgroundColor: theme.palette.wmColor.main,
                                ml: { xs: 0, md: 3 },
                                mt: { xs: 2, md: 0 },
                                width: { xs: "100%", md: "auto" },
                            }}
                            disabled={
                                !questionStart || answerDone ? true : false
                            }
                        >
                            Submit
                        </Button>
                    </Box>
                </form>
            )}
        </>
    );
}
