import React, { useState } from "react";
import imgLogin from "./login-for-chance-to-win-3x-432x192.png";
import superGameImg from "./super-game.png";

import { useTheme } from "@mui/material/styles";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    Typography,
} from "@mui/material";

import DialogGuest from "../DialogGuest/DialogGuest";
import { getLabel } from "../../helpers/Global";
import { coin } from "../../helpers/constant";

export default function LoginTemplate({
    showLoginDialog = () => {},
    display = "block",
    sx = { width: "100%" },
    superGame,
    uiLabels,
}) {
    const theme = useTheme();
    const [guestDialog, setGuestDialog] = useState(false);

    function handleGuestLogin() {
        setGuestDialog(true);
    }

    function handleSignInPopup() {
        let message = { action: "loginModal" };
        // method to send message to parent outside iframe
        window.parent.postMessage(message, "*");
    }

    function handleGoogleLogin() {
        let message = { action: "loginGoogle" };
        // method to send message to parent outside iframe
        window.parent.postMessage(message, "*");
    }

    function handleFacebookLogin() {
        let message = { action: "loginFacebook" };
        // method to send message to parent outside iframe
        window.parent.postMessage(message, "*");
    }

    const buttonSX = theme.home.login.signInButton;
    buttonSX.width = "275px";
    buttonSX.mx = 3;

    return (
        <>
            <Box display={"flex"} justifyContent={"center"} sx={sx}>
                <Button
                    className={`btn-social btn-signin`}
                    variant="contained"
                    sx={buttonSX}
                    onClick={handleSignInPopup}
                >
                    {getLabel(uiLabels, "btnSignIn", "Sign in")}
                </Button>
                <Button
                    className={"btn-social btn-signin"}
                    variant="contained"
                    sx={buttonSX}
                    onClick={handleGuestLogin}
                >
                    {getLabel(uiLabels, "btnPlayGuest", "Play as Guest")}
                </Button>
            </Box>
            <Typography
                variant="body1"
                sx={{
                    fontSize: { xs: "1.1rem", md: "1.5rem" },
                    mt: 3,
                    textAlign: "center",
                    fontWeight: 700,
                }}
                dangerouslySetInnerHTML={{
                    __html: `${getLabel(
                        uiLabels,
                        "labelSignInToEarnMojoPoints",
                        "SIGN IN to earn MojoPoints and qualify for CASH BATTLES!"
                    ).replace(/#0/g, `${coin} `)}`,
                }}
            ></Typography>
            {guestDialog && (
                <DialogGuest
                    open={guestDialog}
                    closeDialog={() => {
                        setGuestDialog(false);
                    }}
                    uiLabels={uiLabels}
                />
            )}
        </>
    );
}
