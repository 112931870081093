import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

export default function AlertDialog() {
    const alert = useSelector(({ alert }) => alert);

    const theme = useTheme();

    const [sx, setSx] = useState({
        dialog: {
            "& .MuiDialog-container": {
                alignItems: "start",
                marginTop: "60px",
            },

            "& .MuiDialogTitle-root, .MuiDialogContent-root, .MuiDialogActions-root":
                {
                    backgroundColor: "transparent",
                },
            "& .MuiPaper-root ": {
                width: "100%",
            },
        },

        dialogBox: {
            backgroundColor: `${theme.palette.backgroundColor.main}`,
            color: "#fff",
            minWidth: { xs: "100%", md: "400px" },
        },
    });

    return (
        <>
            <Dialog
                className={"test-class"}
                fullscreen={"true"}
                open={alert.alert}
                sx={sx.dialog}
            >
                <Box sx={sx.dialogBox}>
                    <DialogTitle
                        sx={{
                            color: "#fff",
                            fontSize: { xs: "1.25rem", sm: "1.5rem" },
                            display: "flex",
                            alignItems: "center",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: `${alert.title}`,
                        }}
                    ></DialogTitle>
                    <DialogContent sx={{}}>
                        <Typography
                            variant="body1"
                            sx={{
                                color: "#fff",
                                fontSize: { xs: "1.1rem", sm: "1.3rem" },
                            }}
                            dangerouslySetInnerHTML={{
                                __html: `${alert.message}`,
                            }}
                        ></Typography>
                    </DialogContent>
                    <DialogActions sx={{}}>
                        {alert.buttons.map((button) => (
                            <Button
                                onClick={button.callback}
                                sx={
                                    button.style ?? {
                                        color: `${theme.palette.wmColor.main}`,
                                    }
                                }
                            >
                                <Typography
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: button.text,
                                    }}
                                ></Typography>
                            </Button>
                        ))}
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}
