import React, { useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import DialogLogin from "../DialogLogin/DialogLogin";
import LoginTemplate from "../LoginTemplate/LoginTemplate";
import LoggedinPlayer from "../LoggedinPlayer/LoggedinPlayer";
import { useSelector } from "react-redux";

export default function HomeLogin({
    player,
    declinedPoints,
    handleJoinQueue,
    handleHowToPlay,
    handleMojoPoints,
    superGame,
    uiLabels,
}) {
    const theme = useTheme();

    const gameStatus = useSelector(({ game }) => game.gameStatus);

    const hasOnclick =
        useMediaQuery(theme.breakpoints.up("md")) == true ? false : true;

    const [loginDialog, setLoginDialog] = useState(false);

    //method to show dialog login
    function showLoginDialog() {
        setLoginDialog(true);
    }

    function HomeLogin() {
        let html = (
            <LoginTemplate
                showLoginDialog={showLoginDialog}
                display={{
                    xs: "none",
                    md: "block",
                }}
                superGame={superGame}
                uiLabels={uiLabels}
            />
        );

        //add check if user is login, then show a different template
        if (player && player.playerId !== null) {
            html = (
                <LoggedinPlayer
                    player={player}
                    gameStatus={gameStatus}
                    declinedPoints={declinedPoints}
                    handleJoinQueue={handleJoinQueue}
                    handleHowToPlay={handleHowToPlay}
                    handleMojoPoints={handleMojoPoints}
                    uiLabels={uiLabels}
                />
            );
        }

        return html;
    }

    return (
        <>
            <Box
                sx={{
                    height: "100%",
                }}
            >
                {HomeLogin()}
                {hasOnclick && (
                    <DialogLogin
                        open={loginDialog}
                        closeDialog={() => {
                            setLoginDialog(false);
                        }}
                    />
                )}
            </Box>
        </>
    );
}
