import React from "react";

import { Backdrop, Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function BackdropLoading({
    open,
    message = "Configuring game questions...",
}) {
    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
            >
                <Grid
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"top"}
                    container
                    spacing={0}
                    sx={{
                        width: "100%",
                        position: "relative",
                        height: "100%",
                        pt: "20%",
                    }}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: "20px", md: "28px" },
                                fontWeight: 700,
                                width: "100%",
                                textAlign: "center",
                                textTransform: "uppercase",
                                textShadow:
                                    "1px 1px rgba(0, 179, 226, 1), 1px -1px rgba(239, 78, 236, 1)",
                            }}
                        >
                            {message}
                            <CircularProgress sx={{ ml: 3 }} />
                        </Typography>
                    </Grid>
                </Grid>
            </Backdrop>
        </>
    );
}
