import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { useSelector } from "react-redux";
import { getLabel } from "../../helpers/Global";

export default function Instructions({ uiLabels }) {
    const roundInfo = useSelector(({ game }) => game.roundInfo);
    const tips = roundInfo && roundInfo.tips ? roundInfo.tips : [];
    const instructions = [
        {
            icon: null,
            text: `<b>${getLabel(
                uiLabels,
                "instructionsChooseWisely",
                "Choose Wisely"
            )}:</b> ${getLabel(
                uiLabels,
                "instructionsChooseWiselyInfo",
                "Click the answer you think is correct before time runs out. Every correct answer gets you closer to glory!"
            )}`,
        },
        {
            icon: null,
            text: `<b>${getLabel(
                uiLabels,
                "instructionsSpeedIsKey",
                "Speed is Key"
            )}:</b> ${getLabel(
                uiLabels,
                "instructionsSpeedIsKeyInfo",
                "Think fast! The quicker you answer, the more points you earn. Show off your lightning-fast reflexes and dominate the leaderboard."
            )}`,
        },
        {
            icon: null,
            text: `<b>${getLabel(
                uiLabels,
                "instructionsAdvanceToVictory",
                "Advance to Victory"
            )}:</b> ${getLabel(
                uiLabels,
                "instructionsAdvanceToVictoryInfo",
                "After 15 questions, the 10 players with the highest scores move on to the next round. Will you be among the elite?"
            )}`,
        },
    ];

    return (
        <>
            <List>
                {instructions.map((item, index) => {
                    return (
                        <ListItem key={index} sx={{ alignItems: "normal" }}>
                            <ListItemIcon>
                                <StarIcon sx={{ color: "white" }} />
                            </ListItemIcon>

                            <Typography
                                variant="body1"
                                dangerouslySetInnerHTML={{
                                    __html: item.text,
                                }}
                            ></Typography>
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
}
