import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getLabel } from "../../helpers/Global";

export default function CashBattleInfo({ uiLabels }) {
    const superGame = useSelector(({ game }) => game.superGame);
    return (
        <>
            {superGame && (
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    sx={{ width: "100%" }}
                >
                    <Box
                        sx={{
                            width: { xs: "100%", md: "auto" },
                            position: "relative",
                        }}
                    >
                        <Box
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "650px",
                                },
                                height: { xs: "180px", md: "230px" },
                            }}
                        >
                            <img
                                src="/image/trivia-cash-battle-banner.png?2024-06-12"
                                style={{ width: "100%", height: "100%" }}
                            />
                        </Box>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "10px",
                                width: "100%",
                                px: { xs: "0px", md: "10px" },
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "1rem", md: "1.3rem" },
                                    width: "100%",
                                    textAlign: "center",
                                    fontFamily: "Trend Sans One Regular",
                                    textShadow: `0 0 1px #fff,
                                    0 0 2px #fff,
                                    0 0 1px yellow,
                                    0 0 2px yellow`,
                                }}
                            >
                                {getLabel(uiLabels, "labelNext", "Next")}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: "#7ed957",
                                    fontSize: { xs: "1.8rem", md: "2.7rem" },
                                    width: "100%",
                                    lineHeight: 1,
                                    textAlign: "center",
                                    fontFamily: "Trend Sans One Regular",
                                    textShadow: `
                                    0 0 3px #7ed957,
                                    0 0 5px #7ed957`,
                                }}
                            >
                                {getLabel(
                                    uiLabels,
                                    "labelCashBattle",
                                    "Cash Battle"
                                )}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                position: "absolute",
                                bottom: { xs: "50px", md: "60px" },
                                width: "100%",
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "16px", md: "22px" },
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            >
                                {superGame.date}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "16px", md: "22px" },
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            >
                                {`${superGame.points} Points / $${superGame.prize} Prize`}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                position: "absolute",
                                bottom: { xs: "10px", md: "20px" },
                                width: "100%",
                                px: { xs: "34px", md: "46px" },
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "0.7rem", md: "0.8rem" },
                                    width: "100%",
                                    textAlign: "center",
                                    color: "#ff66c4",
                                    fontWeight: 600,
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: getLabel(
                                        uiLabels,
                                        "labelRedeemMojoPointsToCompete",
                                        "REDEEM MOJOPOINTS TO COMPETE FOR REAL CASH PRIZES!"
                                    ),
                                }}
                            ></Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
}
