import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { getLabel } from "../../helpers/Global";
import ActiveGames from "../ActiveGames/ActiveGames";
import { useSelector } from "react-redux";
import Games from "../../pages/Games/Games";

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </Box>
    );
}

export default function GamesTab({ uiLabels, theme, socketFromStore }) {
    const [value, setValue] = React.useState(0);

    const multiplayerCategory = useSelector(
        ({ game }) => game.multiplayerCategory
    );
    const multiplayerVideo = useSelector(({ game }) => game.multiplayerVideo);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const tabs = [
        {
            id: "labelPreviousDailyChallenges",
            label: getLabel(
                uiLabels,
                "labelPreviousDailyChallenges",
                "Previous Daily Challenges"
            ),
            sx: {
                color: theme.home.topic.tab,
                color: "white",
                fontSize: "16px",
                fontWeight: 600,
            },
            tabPanel: (
                <>
                    <Games
                        theme={theme}
                        uiLabels={uiLabels}
                        socketFromStore={socketFromStore}
                    />
                </>
            ),
        },

        {
            id: "labelCategoryGames",
            label: getLabel(uiLabels, "labelCategoryGames", "Category Games"),
            sx: {
                color: theme.home.topic.tab,
                color: "white",
                fontSize: "16px",
                fontWeight: 600,
            },
            tabPanel: (
                <>
                    <ActiveGames
                        uiLabels={uiLabels}
                        header={getLabel(
                            uiLabels,
                            "labelCategoryGames",
                            "Category Games"
                        )}
                        games={multiplayerCategory}
                        theme={theme}
                    />
                    <ActiveGames
                        uiLabels={uiLabels}
                        header={getLabel(
                            uiLabels,
                            "labelJoinAGame",
                            "Join a Game!"
                        )}
                        games={multiplayerVideo}
                        gridSize={{ xs: 12, md: 4 }}
                        theme={theme}
                    />
                </>
            ),
        },
    ];

    return (
        <Box>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    borderBottom: "1px solid #444 !important",
                    borderTop: "1px solid #000 !important",
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor={theme.palette.wmcolor}
                    indicatorColor={theme.palette.wmcolor}
                    variant="fullWidth"
                    sx={{
                        backgroundColor: "#262626",
                    }}
                >
                    {tabs.map((tab, index) => {
                        return (
                            <Tab
                                key={`index-${index}`}
                                label={
                                    <Typography
                                        variant="body1"
                                        dangerouslySetInnerHTML={{
                                            __html: `${tab.label}`,
                                        }}
                                        sx={{
                                            fontSize: {
                                                xs: "0.8rem",
                                                md: "0.9rem",
                                            },
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    />
                                }
                                id={tab.id}
                                sx={tab.sx}
                            ></Tab>
                        );
                    })}
                </Tabs>
            </Box>
            <Box>
                {tabs.map((tab, index) => {
                    return (
                        <TabPanel
                            key={index}
                            id={tab.id}
                            value={value}
                            index={index}
                        >
                            {tab.tabPanel}
                        </TabPanel>
                    );
                })}
            </Box>
        </Box>
    );
}
