import React from "react";
import { CssBaseline, Button } from "@mui/material";
import styles from "./Button.module.css";

const GeneralButton = ({
    children,
    handleButtonClick,
    href,
    bgcolor,
    color,
    mRight,
    mt,
    value,
    backgroundImage,
    hoverColor,
    filter,
    id,
    height,
    width,
    minHeight,
    maxHeight,
    border,
    textTransform,
    fontWeight,
    disabled,
}) => {
    return (
        <>
            <CssBaseline />
            <Button
                className={styles.button}
                onClick={handleButtonClick}
                value={value}
                href={href}
                variant="contained"
                disabled={disabled ? disabled : false}
                id={id}
                sx={{
                    height: height ? height : "100%",
                    width: width ? width : "100%",
                    bgcolor: bgcolor,
                    color: color,
                    textTransform: textTransform ? textTransform : "uppercase",
                    marginRight: mRight,
                    borderRadius: "5px",
                    ":hover": {
                        filter: filter,
                        bgcolor: hoverColor,
                    },
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "100% 100%",
                    minHeight: minHeight,
                    maxHeight: maxHeight,
                    border: border,
                    fontWeight: fontWeight ? fontWeight : "400",
                    mt: mt ?? 0,
                }}
            >
                {children}
            </Button>
        </>
    );
};

export default GeneralButton;
