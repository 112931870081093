const initialState = {
    players: [],
    challengedPlayers: [],
    availableOL: 0,
};

const OnlinePlayersReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ONLINE_PLAYERS":
            return Object.assign({}, state, {
                players: action.data,
                availableOL: action.data.length,
            });

        case "ADD_ONLINE_PLAYER": {
            //only append if player is not on the list
            if (
                [...state.players].find(
                    (player) => player.playerId == action.data.playerId
                )
            )
                return state;

            const newArr = [...state.players, action.data];
            return Object.assign({}, state, {
                players: newArr,
                availableOL: newArr.length,
            });
        }

        case "REMOVE_ONLINE_PLAYER": {
            const newArr = [...state.players].filter((player) => {
                return player.playerId != action.data;
            });
            return Object.assign({}, state, {
                players: newArr,
                availableOL: newArr.length,
            });
        }

        case "CHALLENGE_PLAYER":
            //only append if player is not on the list
            if (
                [...state.challengedPlayers].find(
                    (playerId) => playerId == action.data
                )
            ) {
                console.log("PLAYER ALREADY CHALLENGED!!!!");
                return state;
            }

            return Object.assign({}, state, {
                challengedPlayers: [...state.challengedPlayers, action.data],
            });

        case "AVAILABLE_OL":
            return Object.assign({}, state, {
                availableOL: action.data,
            });

        default:
            return state;
    }
};

//HANDLE
export const setOnlinePlayers = (players) => {
    return {
        type: "ONLINE_PLAYERS",
        data: players,
    };
};

export const addOnlinePlayer = (player) => {
    return {
        type: "ADD_ONLINE_PLAYER",
        data: player,
    };
};

export const removeOnlinePlayer = (playerId) => {
    return {
        type: "REMOVE_ONLINE_PLAYER",
        data: playerId,
    };
};

export const challengePlayer = (playerId) => {
    return {
        type: "CHALLENGE_PLAYER",
        data: playerId,
    };
};

export const availableOL = (count) => {
    return {
        type: "AVAILABLE_OL",
        data: count,
    };
};

export default OnlinePlayersReducer;
