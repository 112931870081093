import { Typography } from "@mui/material";
import React from "react";
import { getLabel } from "../../helpers/Global";

export default function LobbyHeader({ theme, uiLabels, header2 }) {
    return (
        <>
            <Typography
                variant="body1"
                sx={{
                    fontSize: { xs: "18px", md: "26px" },
                    fontWeight: 500,
                    width: "100%",
                    textAlign: "center",
                    textTransform: "none",
                    letterSpacing: 2,
                    fontFamily: theme.font.family,
                }}
            >
                {getLabel(uiLabels, "labelGearUp", "Gear up for today's")}
            </Typography>

            <Typography
                variant="body1"
                sx={{
                    fontSize: { xs: "34px", md: "56px" },
                    fontWeight: 700,
                    width: "100%",
                    textAlign: "center",
                    textTransform: "uppercase",
                    letterSpacing: 2,
                    fontFamily: theme.font.family,
                }}
            >
                {header2}!
            </Typography>
        </>
    );
}
