import React from "react";
import { Box, Dialog, DialogTitle, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useTheme } from "@mui/material/styles";

import LoginTemplate from "../LoginTemplate/LoginTemplate";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogLogin({ open, closeDialog }) {
    const theme = useTheme();

    const sx: SxProps = {
        "& .MuiDialog-container": {
            height: "100%",
            width: "100%",
            position: "absolute",
            bottom: 0,
        },
        "& .MuiPaper-root": {
            backgroundColor: `${theme.home.login.bgColor.dialog}`,
            color: "#FFFFFF",
        },
    };

    const handleClose = () => {
        if (closeDialog !== null) {
            closeDialog();
        }
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                scroll="paper"
                sx={sx}
            >
                <DialogTitle>
                    <Box
                        sx={{
                            width: "90px",
                            height: "3px",
                            borderRadius: "4px",
                            backgroundColor: "rgba(217, 217, 217, 0.8);",
                            margin: "auto",
                        }}
                    ></Box>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <LoginTemplate sx={{ marginTop: "16px", width: "100%" }} />
            </Dialog>
        </div>
    );
}
