import React from "react";
import { Box, Typography } from "@mui/material";
import { getLabel } from "../../helpers/Global";
import { coin } from "../../helpers/constant";

export default function HomeCategoryPrize({
    subCategory,
    gameThumbnail,
    uiLabels,
    theme,
    requiredPoints,
}) {
    return (
        <Box>
            <Box
                sx={{
                    width: { xs: "100%", sm: "100%" },
                    margin: "auto",
                    position: "relative",
                }}
            >
                <img
                    src={gameThumbnail}
                    style={{ width: "100%", border: "2px solid #09c" }}
                    alt="thumbnail"
                />
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "0",
                        width: "100%",
                        backgroundColor: "rgba(0,0,0,0.75)",
                        p: "7px 7px 14px 7px",
                    }}
                >
                    <Box display={"flex"} alignItems={"center"}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: "1.1rem", md: "1.2rem" },
                                fontWeight: 700,
                                lineHeight: "normal",
                                letterSpacing: "-.5px",
                            }}
                        >
                            {`${getLabel(
                                uiLabels,
                                "labelCategory",
                                "Category"
                            )}: `}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: "1.6rem", md: "1.6rem" },
                                fontWeight: 700,
                                lineHeight: "normal",
                                letterSpacing: "-.5px",
                                ml: 1,
                            }}
                        >
                            {`${subCategory ?? ""}`}
                        </Typography>
                    </Box>
                    {requiredPoints === 0 && (
                        <Box display={"flex"} alignItems={"center"}>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "1.1rem", md: "1.1rem" },
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                    letterSpacing: "-.5px",
                                }}
                            >
                                {`${getLabel(
                                    uiLabels,
                                    "labelPrize",
                                    "Prize"
                                )}:`}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: { xs: "1.6rem", md: "1.6rem" },
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                    letterSpacing: "-.5px",
                                    ml: 1,
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: `1000 ${coin} MojoPoints`,
                                }}
                            ></Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
