const initialState = {
    correctAnswer: undefined,
};

const AnswersReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_CORRECT_ANSWER":
            return Object.assign({}, state, {
                correctAnswer: action.data,
            });

        default:
            return state;
    }
};

//update correct answer
export const updateCorrectAnswer = (ans) => {
    return async (dispatch) => {
        dispatch({
            type: "UPDATE_CORRECT_ANSWER",
            data: ans,
        });
    };
};

export default AnswersReducer;
