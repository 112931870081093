import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import cssStyle from "./YoutubeVideo.module.css";
import { YT_URL } from "../../helpers/constant";
import { getLabel } from "../../helpers/Global";
import Carousel from "react-material-ui-carousel";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RemoveIcon from "@mui/icons-material/Remove";

import useMediaQuery from "@mui/material/useMediaQuery";

export default function YoutubeVideo({ triviaLivestreams, uiLabels }) {
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down("sm"));
    const [liveStreams, setLiveStreams] = useState([]);

    useEffect(() => {
        let group = 1;
        if (!isXS) {
            group = 2;
        }

        let tempArray = [];
        let currentArray = [];

        triviaLivestreams.forEach((ls) => {
            if (currentArray.length < group) {
                currentArray.push(ls);
                return true;
            }

            tempArray.push([...currentArray]);

            currentArray = [];
        });

        if (currentArray.length > 0) {
            tempArray.push(currentArray);
            currentArray = [];
        }

        setLiveStreams(tempArray);
    }, [isXS, triviaLivestreams]);

    function Item(props) {
        return (
            <Grid
                container
                spacing={0}
                sx={{
                    height: {
                        xs: "200px",
                        sm: "170px",
                        lg: "230px",
                    },
                }}
            >
                {props.item.map((item, i) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                p: 0,
                                m: 0,
                                height: {
                                    xs: "220px",
                                    sm: "170px",
                                    lg: "230px",
                                },
                            }}
                            key={`yt-${i}`}
                        >
                            <iframe
                                className={cssStyle.iframe}
                                src={`${YT_URL}${item}`}
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                                allowFullScreen="allowFullScreen"
                                title="Livestream"
                                frameBorder="0"
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    return (
        <>
            {triviaLivestreams && (
                <Box
                    sx={{
                        mt: "10px",
                        height: "auto",
                        px: { xs: 1, md: 0 },
                        py: 0,
                        position: "relative",
                        border: {
                            xs: "0px solid red",
                            md: `0px solid ${theme.palette.wmColor.main}`,
                        },
                        borderRadius: {
                            xs: "0px",
                            md: `0px`,
                        },
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: "left",
                            fontWeight: 700,
                            lineHeight: "1",
                            mb: 1,
                            px: { xs: 2, md: 0 },
                            letterSpacing: 2,
                            fontFamily: theme.font.family,
                        }}
                    >
                        {getLabel(uiLabels, "labelLivestreams", "Livestreams")}
                    </Typography>

                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Carousel
                            navButtonsAlwaysVisible="true"
                            autoPlay={true}
                            interval="3000"
                            navButtonsProps={{
                                style: {
                                    backgroundColor: `${theme.palette.wmColor.main}`,
                                    padding: 0,
                                    margin: "-300px 10px 0 10px",
                                },
                            }}
                            NextIcon={
                                <ChevronRightIcon
                                    className={cssStyle.carouselNext}
                                />
                            }
                            PrevIcon={
                                <ChevronLeftIcon
                                    className={cssStyle.carouselPrev}
                                />
                            }
                            indicators={false}
                            IndicatorIcon={
                                <RemoveIcon
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: "1.7em",
                                    }}
                                />
                            }
                            indicatorContainerProps={{
                                style: {
                                    marginTop: "25px",
                                },
                            }}
                            activeIndicatorIconButtonProps={{
                                style: {
                                    color: `${theme.palette.wmColor.main}`,
                                },
                            }}
                            sx={{
                                "& .MuiIconButton-root": {
                                    verticalAlign: "text-bottom",
                                },
                            }}
                        >
                            {liveStreams.map((item, i) => {
                                return <Item key={i} item={item} />;
                            })}
                        </Carousel>
                    </Box>
                </Box>
            )}
        </>
    );
}
