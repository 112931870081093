/* eslint-disable no-useless-escape */
import { SOCKET_EVENTS } from "./constant";

//method to ucase first letter of every word
export const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(" ");
};

// method to check device type
export const isMobileOrTablet = function () {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

export const completeLeaderboard = function (rows = [], max = 5) {
    for (let i = rows.length; i < max; i++) {
        rows.push({
            isBlank: true,
            avatar: "?",
            name: "Player",
            score: "",
            correctAnswer: "",
        });
    }

    return rows;
};

export const formatNumber = (number) => {
    return number ? new Intl.NumberFormat().format(number) : "0";
};

export const handleInviteClick = (e) => {
    let message = {
        action: "inviteFriends",
        inviteCode: e && !isNaN(e.playerId) && e.inviteCode ? e.inviteCode : "",
    };
    //method to send message to parent outside iframe
    window.top.postMessage(message, "*");
};

export const putSelfToStart = (players, playerId, playerInFront = false) => {
    if (playerInFront) {
        players.sort((a, b) => {
            const abot = a.isBot ? 1 : 0,
                bbot = b.isBot ? 1 : 0;
            if (abot < bbot) {
                return -1;
            }
            if (abot > bbot) {
                return 1;
            }
            return 0;
        });
    } else {
        //sort top scorer before putting self to top
        players.sort((a, b) => {
            if (a.score < b.score) {
                return 1;
            }
            if (a.score > b.score) {
                return -1;
            }
            return 0;
        });
    }

    //put yourself at the start of the players list
    const myIndex = players.findIndex((player) => player.id == playerId);

    if (myIndex < 0) return players;

    const me = players.find((player) => player.id == playerId);

    players.splice(myIndex, 1);

    players.unshift(me);

    return players;
};

export const isDev = () => {
    const env = process.env.REACT_APP_SOCKET_URL.toLowerCase().trim();

    return (
        env.includes("localhost") ||
        env.includes("api-trivia-public-dev.watchmojo.com")
    );
};

export const eraseGuestData = () => {
    localStorage.removeItem(process.env.REACT_APP_GUEST_KEY);
};

export const saveGuestData = (guestPlayer) => {
    localStorage.setItem(process.env.REACT_APP_GUEST_KEY, guestPlayer);
};

export const getGuestData = () => {
    const temp = localStorage.getItem(process.env.REACT_APP_GUEST_KEY);
    return temp === null ? null : JSON.parse(temp);
};

export const refreshPage = () => {
    let message = { action: "refreshPage" };
    window.top.postMessage(message, "*");
};

export const joinQueue = (
    socketFromStore,
    roomId,
    joinCode,
    payWithPoints = false
) => {
    console.log("NOT JOINING FOR NOW!!!", roomId);
    // return;
    if (socketFromStore) {
        socketFromStore.emit(SOCKET_EVENTS.JOIN_QUEUE, {
            usePoints: payWithPoints,
            joinCode,
            roomId,
        });
    }
};

export const getLabel = (uiLabels, id, defaultText) => {
    return uiLabels && uiLabels[id] ? uiLabels[id] : defaultText;
};

export const isPrizeWinner = (winner) => {
    return winner && winner.prizeAmount && winner.prizeAmount > 0
        ? true
        : false;
};

export const isChampion = (player) => {
    if (!player || !player.stand || player.stand !== 1) {
        return false;
    }

    return true;
};

export const isSpectator = (player) => {
    if (!player) {
        return true;
    }

    return player.spectator ? true : false;
};

export const isCashBattle = (points, winner) => {
    if (isPrizeWinner(winner)) {
        return true;
    }

    if (!points || points < 1) {
        return false;
    }

    return true;
};

export const getTotalPoints = (player) => {
    if (!player) {
        return 0;
    }
    const bonusPoints = player.bonusPoints;
    if (!bonusPoints) {
        return 0;
    }

    let total = 0;

    bonusPoints.forEach((point) => {
        total += point.points;
    });

    return total;
};

export const playerRedeemed = (player) => {
    if (!player) {
        return true;
    }

    if (player.redeem) {
        return true;
    }

    return false;
};

export const findPlayer = (players, playerId) => {
    if (!players || !playerId) return undefined;

    return players.find(
        (player) => player.id.toString() == playerId.toString()
    );
};

export const toggleMojoPoints = (show, language = false) => {
    let message = {
        action: show ? "showMojoPoints" : "hideMojoPoints",
        language: language,
    };

    // method to send message to parent outside iframe
    window.parent.postMessage(message, "*");
};

export const updateMojoPoints = (points) => {
    let message = { action: "updateMojoPoints", points: points };

    // method to send message to parent outside iframe
    window.parent.postMessage(message, "*");
};

export const isWidget = (location) => {
    if (!location || !location.pathname) {
        return false;
    }

    return location.pathname.toLowerCase().replace(/\//g, "") === "widget";
};

export const isReplayGame = (ogGameDate) => {
    return ogGameDate ? true : false;
};

export const login = () => {
    let message = { action: "loginModal" };
    // method to send message to parent outside iframe
    window.parent.postMessage(message, "*");
};
