import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Box, Typography, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import cssStyle from "./WhatAreMojoPoints.module.css";
import { CssTwoTone } from "@mui/icons-material";
import { getLabel } from "../../helpers/Global";
import { coin } from "../../helpers/constant";
import WhatAreMojoPointsContent from "./WhatAreMojoPointsContent";

export default function WhatAreMojoPoints({
    show = false,
    handleClose,
    uiLabels,
}) {
    const theme = useTheme();

    const sx = {
        dialog: {
            "& .MuiDialog-container": {
                alignItems: "start",
                marginTop: { xs: "0px", md: "40px" },
            },

            "& .MuiDialogTitle-root, .MuiDialogContent-root, .MuiDialogActions-root":
                {
                    backgroundColor: "transparent",
                },
            "& .MuiPaper-root ": {
                backgroundColor: `${theme.palette.backgroundColor.main}`,
                width: "100%",
                minWidth: { xs: "100%", md: "850px" },
                margin: { xs: "0" },
                overflow: "hidden",
            },

            "& .MuiDialogContent-root": {
                scrollbarWidth: "thin",
                scrollbarTrackColor: "#888",
                scrollbarColor: "rgba(0, 179, 226, 1) #888",
            },

            "& .MuiDialogContent-root::-webkit-scrollbar": {
                width: "5px",
                height: "5px",
            },

            "& .MuiDialogContent-root::-webkit-scrollbar-track": {
                background: "#888",
            },

            "& .MuiDialogContent-root::-webkit-scrollbar-thumb": {
                background: "rgba(0, 179, 226, 1)",
            },

            "& .MuiDialogContent-root::-webkit-scrollbar-thumb:hover": {
                background: "rgba(0, 179, 226, 0.5)",
            },
        },
    };

    return (
        <>
            <Dialog fullscreen open={show} sx={sx.dialog} onClose={handleClose}>
                <DialogTitle
                    sx={{
                        color: `white`,
                        fontSize: { xs: "1.25rem", sm: "1.5rem" },
                    }}
                    dangerouslySetInnerHTML={{
                        __html: `${getLabel(
                            uiLabels,
                            "whatAreMojoPointsTitle",
                            "What are MojoPoints?"
                        ).replace(/#0/g, `${coin}&nbsp;`)}`,
                    }}
                ></DialogTitle>
                <DialogContent sx={{ overflow: "hidden" }}>
                    <WhatAreMojoPointsContent uiLabels={uiLabels} />
                </DialogContent>
                <DialogActions sx={{}}>
                    <Button
                        onClick={handleClose}
                        sx={{
                            color: `${theme.palette.wmColor.main}`,
                        }}
                    >
                        {getLabel(uiLabels, "btnGotIt", "Got it")}!
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
