import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import HomeWeeklyLeaderboard from "../HomeWeeklyLeaderboard/HomeWeeklyLeaderboard";
import HowToSection from "../HowTo/HowToSection";
import { getLabel } from "../../helpers/Global";
import { coin } from "../../helpers/constant";
import MojoPointsLegend from "../MojoPointsLegend/MojoPointsLegend";
import WhatAreMojoPointsContent from "../WhatAreMojoPoints/WhatAreMojoPointsContent";

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

export default function HomeBottomTab({ player, uiLabels, theme }) {
    const [value, setValue] = React.useState(0);
    const [tabLoop, setTabLoop] = useState(true);
    const [tabTimeout, setTabTimout] = useState(null);

    const tabs = [
        {
            id: "labelHowToPlay",
            label: getLabel(uiLabels, "labelHowToPlay", "How to Play"),
            sx: {
                color: theme.home.topic.tab,
                width: "50%",
                color: "white",
                fontSize: "16px",
                fontWeight: 600,
            },
            tabPanel: <HowToSection uiLabels={uiLabels} />,
        },

        {
            id: "whatAreMojoPointsTitle",
            label: getLabel(
                uiLabels,
                "whatAreMojoPointsTitle",
                "What are MojoPoints?"
            ).replace(/#0/g, `&nbsp;${coin}&nbsp;`),
            sx: {
                color: theme.home.topic.tab,
                width: "50%",
                color: "white",
                fontSize: "16px",
                fontWeight: 600,
            },
            tabPanel: <WhatAreMojoPointsContent uiLabels={uiLabels} />,
        },

        {
            id: "labelLegend",
            label: `${getLabel(
                uiLabels,
                "labelLegend",
                "How to get MojoPoints"
            ).replace(/#0/g, `&nbsp;${coin}&nbsp;`)}`,
            sx: {
                color: theme.home.topic.tab,
                width: "50%",
                color: "white",
                fontSize: "16px",
                fontWeight: 600,
            },
            tabPanel: (
                <>
                    <MojoPointsLegend uiLabels={uiLabels} theme={theme} />
                </>
            ),
        },
    ];

    useEffect(() => {
        let timeout = null;
        if (tabLoop) {
            timeout = setTimeout(() => {
                const nextIndex = value < tabs.length - 1 ? value + 1 : 0;
                setValue(nextIndex);
            }, 10000);
        }

        setTabTimout(timeout);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [value]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleClick = (event: React.SyntheticEvent, newValue: number) => {
        clearTimeout(tabTimeout);
        setTabLoop(false);
    };

    return (
        <>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    borderBottom: "1px solid #444 !important",
                    borderTop: "1px solid #000 !important",
                    px: theme.space.home.px,
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    onClick={handleClick}
                    textColor={theme.palette.wmcolor}
                    indicatorColor={theme.palette.wmcolor}
                    variant="fullWidth"
                    sx={{
                        backgroundColor: "#262626",
                    }}
                >
                    {tabs.map((tab, index) => {
                        return (
                            <Tab
                                key={`index-${index}`}
                                label={
                                    <Typography
                                        variant="body1"
                                        dangerouslySetInnerHTML={{
                                            __html: `${tab.label}`,
                                        }}
                                        sx={{
                                            fontSize: {
                                                xs: "0.8rem",
                                                md: "0.9rem",
                                            },
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    />
                                }
                                id={tab.id}
                                sx={tab.sx}
                            ></Tab>
                        );
                    })}
                </Tabs>
            </Box>
            <Box
                sx={{
                    px: theme.space.home.px,
                }}
            >
                {tabs.map((tab, index) => {
                    return (
                        <TabPanel
                            key={index}
                            id={tab.id}
                            value={value}
                            index={index}
                        >
                            {
                                //Topics / Challenge yourself / practice trivia section
                            }
                            {tab.tabPanel ? tab.tabPanel : <></>}
                        </TabPanel>
                    );
                })}
            </Box>
        </>
    );
}
