const initialState = {
    alert: false, // toggle true/false to show alert dialog
    title: "Confirm", //alert dialog title
    message: "", //alert dialog message
    buttons: [],
    style: null,
};

const AlertReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ALERT":
            return Object.assign({}, state, {
                alert: action.data.alert,
                title: action.data.title,
                message: action.data.message,
                buttons: action.data.buttons,
            });

        case "REMOVE_ALERT":
            return Object.assign({}, state, {
                alert: false,
            });

        default:
            return state;
    }
};

//HANDLE
export const addAlert = ({
    alert = true,
    title = "",
    message = "",
    buttons = [],
}) => {
    return {
        type: "ALERT",
        data: {
            alert: alert,
            title: title,
            message: message,
            buttons: buttons,
        },
    };
};

export const removeAlert = () => {
    return {
        type: "REMOVE_ALERT",
    };
};

export default AlertReducer;
