import { CssBaseline } from "@mui/material";

import Answer from "./Answer";

const MultipleAnswers = ({
    theme,
    answers,
    buttonBackgroundColor,
    correctAnswer,
    matches,
    clicked,
    selectedAns,
    handleAnswerClick,
    questionStart,
    spectator = false,
    hideAnswer,
    yourAnswer,
}) => {
    return (
        <>
            <CssBaseline />
            {answers &&
                answers.map((answer, index) => (
                    <Answer
                        key={index}
                        theme={theme}
                        answer={answer}
                        buttonBackgroundColor={buttonBackgroundColor}
                        correctAnswer={correctAnswer}
                        matches={matches}
                        clicked={clicked}
                        selectedAns={selectedAns}
                        handleAnswerClick={handleAnswerClick}
                        questionStart={questionStart}
                        spectator={spectator}
                        hideAnswer={hideAnswer}
                        yourAnswer={yourAnswer}
                    />
                ))}
        </>
    );
};

export default MultipleAnswers;
