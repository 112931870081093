import React from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Chip,
    Link,
    Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { getLabel } from "../../helpers/Global";
import PersonIcon from "@mui/icons-material/Person";
import cssStyle from "./ActiveGame.module.css";

export default function ActiveGame({ game, uiLabels }) {
    const theme = useTheme();

    const handleJoinGame = () => {
        window.open(game.url);
    };

    function PlayerIcon(props) {
        return (
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <PersonIcon />
                <Typography
                    sx={{
                        color: "white",
                        fontSize: { xs: "1.1rem", fontWeight: 700 },
                    }}
                >
                    {game.players}
                </Typography>
            </Box>
        );
    }

    return (
        <Card
            sx={{
                backgroundColor: "#000",
                padding: "3px",
                borderRadius: "0.5rem",
            }}
        >
            <CardContent sx={{ padding: 0, position: "relative" }}>
                <Link href="#" onClick={handleJoinGame}>
                    <img
                        src={game.triviaImage}
                        alt={game.triviaTitle}
                        style={{ maxWidth: "100%" }}
                    />
                    <Box className={cssStyle.containerTitle}>
                        <Typography sx={{ color: "white" }}>
                            {game.gameType !== "category"
                                ? game.triviaTitle
                                : ""}
                        </Typography>
                    </Box>
                    {game.gameHost && (
                        <Chip
                            className={cssStyle.containerHost}
                            avatar={
                                <Avatar
                                    alt={game.gameHost.name}
                                    src={game.gameHost.avatar}
                                />
                            }
                            label={game.gameHost.name}
                            variant="outlined"
                        />
                    )}
                </Link>
            </CardContent>
            <CardActions>
                <Button
                    className={`btnJoinGame`}
                    variant="contained"
                    sx={{
                        fontSize: { xs: "16px", md: "14px" },
                        height: { sx: "auto", md: "100%" },
                        width: "100%",
                        backgroundColor: "#09c; !important",
                        fontWeight: 600,
                        "&:hover": {
                            backgroundColor: "#00b3e2 !important",
                        },
                    }}
                    endIcon={<PlayerIcon />}
                    title={`${game.players} player${
                        game.players > 1 ? "s" : ""
                    } playing`}
                    onClick={handleJoinGame}
                >
                    {getLabel(uiLabels, "btnJoinGame", "Join Game")}
                </Button>
            </CardActions>
        </Card>
    );
}
