const initialState = {
    currentTitle: null,
    currentPid: null,
    currentMode: null,
    currentCategory: null,
    currentTriviaImage: null,
};

const SettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TITLE":
            return Object.assign({}, state, {
                ...state,
                currentTitle: action.data,
            });

        case "ADD_PID":
            return Object.assign({}, state, {
                ...state,
                currentPid: action.data,
            });

        case "ADD_MODE":
            return Object.assign({}, state, {
                ...state,
                currentMode: action.data,
            });

        case "ADD_CATEGORY":
            return Object.assign({}, state, {
                ...state,
                currentCategory: action.data,
            });

        case "ADD_TRIVIA_IMAGE":
            return Object.assign({}, state, {
                ...state,
                currentTriviaImage: action.data,
            });

        default:
            return state;
    }
};

//adding game title
export const addTitle = (title) => {
    return {
        type: "ADD_TITLE",
        data: title,
    };
};

//adding game status
export const addPid = (pid) => {
    return {
        type: "ADD_PID",
        data: pid,
    };
};

//adding game mode
export const addMode = (mode) => {
    return {
        type: "ADD_MODE",
        data: mode,
    };
};

//adding game category
export const addCategory = (category) => {
    return {
        type: "ADD_CATEGORY",
        data: category,
    };
};

export const addTriviaImage = (image) => {
    return {
        type: "ADD_TRIVIA_IMAGE",
        data: image,
    };
};

export default SettingsReducer;
