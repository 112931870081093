import React from "react";
import { Box, Typography } from "@mui/material";
import HomeLeaderboard from "../HomeLeaderboard/HomeLeaderboard";
import { completeLeaderboard, getLabel } from "../../helpers/Global";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";

export default function HomeWeeklyLeaderboard({ player, uiLabels }) {
    const theme = useTheme();

    const isXS = useMediaQuery(theme.breakpoints.down("sm"));

    const leaderboard = useSelector(({ game }) => game.weeklyLeaderboard);
    const allTimeLeaderboard = useSelector(
        ({ game }) => game.allTimeLeaderboard
    );

    function createLeaderboard() {
        let maxGroup = 10;

        if (isXS) {
            maxGroup = 10;
        }

        let tempLeaderboard = [...leaderboard];

        if (player.playerStats && player.playerStats.position > 10) {
            tempLeaderboard.push({
                id: player.playerId,
                avatar: player.playerAvatar,
                name: player.playerName,
                score: player.playerStats.score,
                correctAnswer: player.playerStats.correctAnswer,
                position: player.playerStats.position,
            });
        }

        let tempAllTimeLeaderboard = [...allTimeLeaderboard];
        if (player.playerStats && player.playerStats.allTimePosition > 10) {
            tempAllTimeLeaderboard.push({
                id: player.playerId,
                avatar: player.playerAvatar,
                name: player.playerName,
                score: player.playerStats.allTimeScore,
                correctAnswer: player.playerStats.allTimeCorrectAnswer,
                position: player.playerStats.allTimePosition,
            });
        }

        return (
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: {
                        md: "repeat(2, 1fr)",
                        xs: "repeat(1, 1fr)",
                    },
                    gridGap: "1rem",
                }}
            >
                <Box>
                    <Typography variant="body1">
                        {getLabel(
                            uiLabels,
                            "labelPlayersOfTheWeek",
                            "This Week"
                        )}
                    </Typography>
                    <HomeLeaderboard
                        leaderboard={tempLeaderboard}
                        ptsInline={false}
                        showHeader={true}
                        startPosition={0}
                        isEmpty={tempLeaderboard.length === 0}
                        player={player}
                        uiLabels={uiLabels}
                    />
                </Box>

                <Box>
                    <Typography variant="body1">
                        {getLabel(uiLabels, "labelAllTimePlayers", "All Time")}
                    </Typography>
                    <HomeLeaderboard
                        leaderboard={tempAllTimeLeaderboard}
                        ptsInline={false}
                        showHeader={true}
                        startPosition={0}
                        isEmpty={tempAllTimeLeaderboard.length === 0}
                        player={player}
                        uiLabels={uiLabels}
                    />
                </Box>
            </Box>
        );
    }

    return <>{createLeaderboard()}</>;
}
