import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import GameRoutes from "./routes";

const App = () => {
    return (
        <Router>
            <GameRoutes />
        </Router>
    );
};

export default App;
