import { Box, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { getLabel } from "../../helpers/Global";

export default function AlertCookie({ uiLabels }) {
    const theme = useTheme();
    const [dismiss, setDismiss] = useState(false);
    return (
        <>
            {!dismiss && (
                <Box
                    sx={{
                        padding: 1,
                        my: 2,
                        border: `2px solid ${theme.palette.wmColor.main}`,
                    }}
                >
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                        {getLabel(
                            uiLabels,
                            "labelPrivacyPolicy",
                            "Privacy Policy"
                        )}
                    </Typography>
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Typography
                            variant="body1"
                            sx={{ textAlign: "center" }}
                        >
                            {getLabel(
                                uiLabels,
                                "messagePrivacyPolicy",
                                "Please accept cookies to get the full Trivia experience"
                            )}
                            !{" "}
                            <Link
                                href="#"
                                sx={{
                                    ml: 2,
                                    color: theme.palette.wmColor.main,
                                    textDecoration: "none",
                                }}
                                onClick={() => {
                                    let message = {
                                        action: "acceptWatchmojoCookie",
                                    };
                                    window.top.postMessage(message, "*");
                                }}
                            >
                                {getLabel(uiLabels, "btnAccept", "Accept")}
                            </Link>
                            <Link
                                href="#"
                                sx={{
                                    ml: 2,
                                    color: "#ba000d",
                                    textDecoration: "none",
                                }}
                                onClick={() => {
                                    setDismiss(true);
                                }}
                            >
                                {getLabel(uiLabels, "btnReject", "Reject")}
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    );
}
