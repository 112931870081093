import React from "react";

import { Avatar, Badge, Typography, Box, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { AVATAR_SIZE } from "../../helpers/constant";
import playerCSS from "../Player/Player.module.css";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { getLabel } from "../../helpers/Global";

export default function PlayerEndSreen({ index, player, uiLabels }) {
    const theme = useTheme();
    const me = useSelector(({ player }) => player);

    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    return (
        <>
            <Grid
                container
                item
                alignItems="center"
                data-tooltip={
                    me.playerId === player.id
                        ? `${getLabel(
                              uiLabels,
                              "labelYourPosition",
                              "Your position"
                          )} - ${index + 1} of ${players.length}`
                        : null
                }
                columnSpacing={2}
                sx={{
                    py: 0,
                    m: 0,
                    borderTop: "1px solid gray",
                    "&:before": {
                        width: "auto !important",
                    },
                }}
                className={me.playerId === player.id ? playerCSS.isMe : null}
            >
                <Grid
                    item
                    align="center"
                    xs={1.25}
                    sm={1.25}
                    sx={{
                        bgcolor: "#222222",
                        py: 2,
                        pl: "0 !important",
                    }}
                >
                    <Typography
                        noWrap
                        variant="body1"
                        align="center"
                        sx={{
                            fontSize: "1.5rem",
                        }}
                    >
                        {index + 1}
                    </Typography>
                </Grid>
                <Grid item xs={7.75} sm={7.75}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="left"
                    >
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            sx={{
                                width: "auto",
                                mt: 0,
                                ml: 1,
                            }}
                        >
                            <Avatar
                                src={player.avatar}
                                sx={{
                                    width: {
                                        xs: AVATAR_SIZE.leaderboard.xs,
                                        sm: AVATAR_SIZE.leaderboard.sm,
                                    },
                                    height: {
                                        xs: AVATAR_SIZE.leaderboard.xs,
                                        sm: AVATAR_SIZE.leaderboard.sm,
                                    },
                                }}
                            >
                                {" "}
                            </Avatar>
                        </Badge>
                        <Typography
                            noWrap
                            variant="body1"
                            sx={{
                                ml: 2,
                                maxWidth: "100%",
                                fontSize: "1.3rem",
                            }}
                        >
                            {player.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3} sm={3}>
                    <Grid
                        item
                        align="center"
                        sx={{
                            fontWeight: "bold",
                            fontSize: "1.6rem",
                            lineHeight: 1,
                        }}
                    >
                        {player.score}
                    </Grid>
                    <Grid item align="center">
                        <Typography
                            color={theme.palette.textColor.faded}
                            sx={{
                                fontSize: "1.1rem",
                                textTransform: "uppercase",
                                lineHeight: 1,
                            }}
                        >
                            {getLabel(uiLabels, "labelPoints", "Points")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
