import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import cssStyle from "./Answer.module.css";
import { titleCase } from "../../helpers/Global";

export default function Answer({
    theme,
    answer,
    buttonBackgroundColor,
    correctAnswer,
    matches,
    clicked,
    selectedAns,
    handleAnswerClick,
    questionStart,
    spectator = false,
    hideAnswer,
    yourAnswer,
}) {
    const [bgColor, setBGColor] = useState(theme.palette.correct.main);
    const [hoverBG, setHoverBG] = useState(theme.palette.correct.main);

    useEffect(() => {
        if (spectator) {
            setBGColor(theme.palette.disabled.main);
            setHoverBG(theme.palette.disabled.main);
            return;
        }
        let bgColor = buttonBackgroundColor;

        if (
            questionStart === false &&
            answer.id == yourAnswer &&
            correctAnswer != answer.id
        ) {
            bgColor = theme.palette.inCorrect.main;
        }

        if (questionStart === false && correctAnswer == answer.id) {
            bgColor = theme.palette.correct.main;
        }

        setBGColor(bgColor);

        const hover =
            questionStart === false && correctAnswer == answer.id
                ? theme.palette.correct.main
                : buttonBackgroundColor;

        setHoverBG(hover);
    }, [questionStart, correctAnswer, hideAnswer, spectator]);

    return (
        <Button
            name={answer.id}
            key={answer.id}
            className={
                hideAnswer.includes(answer.id.toString())
                    ? cssStyle.fadeAnswer
                    : cssStyle.normal
            }
            sx={{
                backgroundColor: bgColor,
                color: `${theme.palette.textColor.main} !important`,
                height: matches ? "45px" : "40px",
                borderRadius: theme.borderRadius.secondary,
                "&:hover": {
                    backgroundColor: hoverBG,
                    color: `${theme.palette.textColor.main} !important`,
                },
                opacity:
                    clicked &&
                    selectedAns != answer.id &&
                    correctAnswer != answer.id
                        ? "0.5"
                        : "1",
                filter:
                    clicked && selectedAns == answer.id
                        ? "brightness(1.2)"
                        : "brightness(1)",
            }}
            onClick={() => handleAnswerClick(answer.id)}
            disabled={
                clicked ||
                !questionStart ||
                spectator ||
                hideAnswer.includes(answer.id.toString())
            }
        >
            <Typography
                variant="body1"
                sx={{
                    fontWeight: "600",
                    textTransform: "capitalize",
                    lineHeight: "1",
                    fontSize:
                        answer.answer.split(" ").length < 5
                            ? theme.play.buttonAnswer.fontSize
                            : theme.play.buttonAnswer.fontSizeSM,
                    textShadow: "0.5px 0.5px 0.5px #666",
                }}
            >
                <span
                    style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                    }}
                >
                    {["true", "false"].includes(answer.id)
                        ? titleCase(answer.answer)
                        : answer.answer}{" "}
                </span>
                {questionStart === false && correctAnswer == answer.id ? (
                    <DoneIcon
                        sx={{
                            fontWeight: "700",
                            lineHeight: "normal",
                            verticalAlign: "middle",
                            paddingLeft: "5px",
                            paddingBottom: "5px",
                        }}
                    />
                ) : (
                    ""
                )}
            </Typography>
        </Button>
    );
}
