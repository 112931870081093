import React from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Link,
    Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { getLabel } from "../../helpers/Global";
import PersonIcon from "@mui/icons-material/Person";
import cssStyle from "./Game.module.css";

export default function Game({ game, uiLabels }) {
    const theme = useTheme();

    const handleJoinGame = () => {
        window.top.location = `${process.env.REACT_APP_WM_URL}/play/${game["_id"]}`;
    };

    return (
        <Card
            sx={{
                backgroundColor: "#000",
                padding: "3px",
                borderRadius: "0.5rem",
            }}
        >
            <CardContent sx={{ padding: 0, position: "relative" }}>
                <Link href="#" onClick={handleJoinGame}>
                    <img
                        src={game.gameThumbnail}
                        alt={game.subCategory}
                        style={{ width: "100%" }}
                    />
                    <Box className={cssStyle.containerTitle}>
                        <Typography sx={{ color: "white" }}>
                            {game.title ?? game.subCategory}
                        </Typography>
                    </Box>
                </Link>
            </CardContent>
            <CardActions>
                <Button
                    className={`btnJoinGame`}
                    variant="contained"
                    sx={{
                        fontSize: { xs: "16px", md: "14px" },
                        height: { sx: "auto", md: "100%" },
                        width: "100%",
                        backgroundColor: "#09c; !important",
                        fontWeight: 600,
                        "&:hover": {
                            backgroundColor: "#00b3e2 !important",
                        },
                    }}
                    onClick={handleJoinGame}
                >
                    {getLabel(uiLabels, "btnPlay", "PLay")}
                </Button>
            </CardActions>
        </Card>
    );
}
